<template>
  <div v-if="data.length > 0">
    {{ title }}:
    <ul class="component-list">
      <li
        v-for="item in data"
        :key="item.id"
      >
        {{ plain ? item.title : itemText(item) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ObjectsList',
  props: {
    title: { type: String, required: true },
    data: { type: Array, required: true },
    plain: { type: Boolean, default: false }
  },
  methods: {
    itemText (item) {
      return `[${item.id}] ${item.title}`;
    }
  }
};
</script>

<style scoped>
    .component-list {
        font-size: 0.7rem;
        list-style: none;
        margin: 2px 0 0 0;
        padding: 0;
    }
</style>
