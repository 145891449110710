<template>
  <div>
    {{ data.map(item => item.title).join(', ') }}
  </div>
</template>

<script>
export default {
  name: 'CitiesCell',
  props: {
    data: { type: Array, required: true }
  }
};
</script>
