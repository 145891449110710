<template>
  <div>
    <AppSuggest
      max-items-count="10"
      placeholder="Выберите город"
      :value="selected"
      :items="formattedOptions"
      @input="filterItems"
      @change="onChange"
    />
  </div>
</template>

<script>
import AppSuggest from '@/components/AppSuggest';

export default {
  name: 'LocalitySelector',
  components: {
    AppSuggest
  },
  props: {
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null }
  },
  data () {
    return {
      selected: null,
      options: [],
      formattedOptions: []
    };
  },
  created () {
    this.$store.dispatch('objects/loadLocalityOptions').then(() => {
      this.options = this.$store.getters['objects/localityOptions'].map(item => {
        return {
          value: item.id,
          text: item.title
        };
      });
      this.formattedOptions = this.options;
    });
  },
  methods: {
    filterItems (query) {
      if (query === '') {
        this.$emit('input', null);
        this.formattedOptions = this.options;
      } else {
        this.formattedOptions = this.options.filter(item => item.text?.toLowerCase().includes(query?.toLowerCase()));
      }
    },
    onChange (value) {
      this.$emit('input', value.value);
    }
  }
};
</script>
