<template>
  <b-tab
    title="ТК"
    href="#service-info"
  >
    <b-card-text>
      <b-table
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(delivery_time_options)="row">
          <delivery-time-options-cell :data="row.item.delivery_time_options" />
        </template>
        <template #cell(extra_services)="row">
          <extra-service-options-cell :data="row.item.extra_services" />
        </template>
        <template #cell(is_enabled)="row">
          <b-form-checkbox
            v-model="row.item.is_enabled"
          />
        </template>
        <template #cell(save)="row">
          <b-button
            variant="info"
            class="ml-2"
            @click="save(row.item.tcid, row.item.is_enabled)"
          >
            сохранить
          </b-button>
        </template>
      </b-table>
      <b-button
        variant="info"
        @click="clearCache"
      >
        Сбросить кеш
      </b-button>
    </b-card-text>
  </b-tab>
</template>

<script>
import api from '@/api';
import DeliveryTimeOptionsCell from '@/pages/AdminArea/ServiceInfoTab/DeliveryTimeOptionsCell';
import ExtraServiceOptionsCell from '@/pages/AdminArea/ServiceInfoTab/ExtraServiceOptionsCell';

export default {
  name: 'ServiceInfoTab',
  components: {
    DeliveryTimeOptionsCell,
    ExtraServiceOptionsCell
  },
  data () {
    return {
      fields: [
        { key: 'tcid', label: 'Внутренний идентификатор' },
        { key: 'label', label: 'Название' },
        { key: 'delivery_time_options', label: 'Время доставки', thClass: 'component-fixed-column' },
        { key: 'extra_services', label: 'Доп. услуги' },
        { key: 'is_enabled', label: 'Включена' },
        { key: 'save', label: 'Сохранение' }
      ],
      items: []
    };
  },
  created () {
    this.$store.dispatch('objects/loadTransportCompanies').then(() => this.loadData());
  },
  methods: {
    loadData () {
      this.items = this.$store.getters['objects/transportCompanies'].map(item => {
        return item;
      });
    },
    save (tcid, isEnabled) {
      api.updateTransportCompany(tcid, isEnabled);
    },
    clearCache () {
      api.transportCompaniesClearCache();
    }
  }
};
</script>

<style scoped>
.component-fixed-column {
  width: 16rem;
}
</style>
