<template>
  <b-card no-body>
    <b-tabs
      ref="tabs"
      v-model="tabIndex"
      card
      lazy
      @input="showTab"
    >
      <localities-tab />
      <regions-tab />
      <warehouses-tab />
      <base-points-tab />
      <service-info-tab />
      <tariffs-tab />
      <clients-tab />
      <companies-tab />
      <legals-tab />
      <dimensions-tab />
    </b-tabs>
  </b-card>
</template>

<script>
import BasePointsTab from '@/pages/AdminArea/BasePointsTab';
import ClientsTab from '@/pages/AdminArea/ClientsTab';
import CompaniesTab from '@/pages/AdminArea/CompaniesTab';
import DimensionsTab from '@/pages/AdminArea/DimensionsTab';
import LegalsTab from '@/pages/AdminArea/LegalsTab';
import LocalitiesTab from '@/pages/AdminArea/LocalitiesTab';
import RegionsTab from '@/pages/AdminArea/RegionsTab';
import ServiceInfoTab from '@/pages/AdminArea/ServiceInfoTab';
import TariffsTab from '@/pages/AdminArea/TariffsTab/index.vue';
import WarehousesTab from '@/pages/AdminArea/WarehousesTab';

export default {
  name: 'AdminArea',
  components: {
    BasePointsTab,
    ClientsTab,
    CompaniesTab,
    DimensionsTab,
    LegalsTab,
    LocalitiesTab,
    RegionsTab,
    ServiceInfoTab,
    TariffsTab,
    WarehousesTab
  },
  data () {
    return {
      tabIndex: 0
    };
  },
  mounted () {
    if (location.hash === '') {
      location.hash = this.hrefByIndex(this.tabIndex);
    } else {
      this.$refs.tabs.$children.forEach((item, index) => {
        if (item.$el.attributes.href.value === location.hash) {
          this.$nextTick(() => {
            this.tabIndex = index;
          });
        }
      });
    }
  },
  methods: {
    showTab (index) {
      location.hash = this.hrefByIndex(index);
    },
    hrefByIndex (index) {
      return this.$refs.tabs.$children[index].$el.attributes.href.value;
    }
  }
};
</script>
