import auth from '@/auth';

const prefix = '/api/v1';

const get = (path) => auth.axios.get(prefix + path);
const post = (path, data) => auth.axios.post(prefix + path, data);
const put = (path, data) => auth.axios.put(prefix + path, data, { headers: { 'Content-Type': 'application/json' } });
const del = (path) => auth.axios.delete(prefix + path);

const SERVICE_OPTION_COST_TYPE_PERCENTS = 'percents';
const SERVICE_OPTION_COST_TYPE_KOPEKS = 'kopeks';

const LOCALITY_FILTER_KIND_LABEL = 'label';
const LOCALITY_FILTER_KIND_FIAS = 'fias';
const LOCALITY_FILTER_KIND_LEVEL = 'level';
const LOCALITY_FILTER_KIND_REGION = 'region';

export default {
  SERVICE_OPTION_COST_TYPE_PERCENTS,
  SERVICE_OPTION_COST_TYPE_KOPEKS,

  LOCALITY_FILTER_KIND_LABEL,
  LOCALITY_FILTER_KIND_FIAS,
  LOCALITY_FILTER_KIND_LEVEL,
  LOCALITY_FILTER_KIND_REGION,

  basePoints (tcid, whid, fias) {
    return get(`/locality/cache?tcid=${tcid}&whc=${whid}&cfias=${fias}`);
  },
  calculateBasePoints (tcid, whid, fiases) {
    return post(`/locality/cache-calculate?tcid=${tcid}&whc=${whid}`, fiases);
  },
  filterLocalitiesWithLabel (kind, label) {
    return post('/locality/filter', { kind, filter: { label } });
  },
  filterLocalitiesWithFias (kind, fias) {
    return post('/locality/filter', { kind, filter: { fias } });
  },
  filterLocalitiesWithLevel (kind, level) {
    return post('/locality/filter', { kind, filter: { level } });
  },
  filterLocalitiesWithRegion (kind, region) {
    return post('/locality/filter', { kind, filter: { region } });
  },
  getLocalityOptions () {
    return get('/locality-options');
  },
  fetchWarehouses () {
    return get('/warehouse');
  },
  warehousesClearCache () {
    return get('/warehouse/clear-cache');
  },
  getProductOptions () {
    return get('/product-options');
  },
  fetchTransportCompanies () {
    return get('/transport-company');
  },
  updateTransportCompany (tcid, isEnabled) {
    return post(`/transport-company/${tcid}`, { is_enabled: isEnabled });
  },
  transportCompaniesClearCache () {
    return get('/transport-company/clear-cache');
  },
  fetchTariffs () {
    return get('/transport-company/tariff');
  },
  tariffsClearCache () {
    return get('/transport-company/tariff/clear-cache');
  },
  fetchDimensionsByVolume () {
    return get('/dimensions-by-volume');
  },
  putTuningRule (id, service, isActive, name, vendors, products, isThreshold, threshold, length, width, height, weight, dimWeight, cities, cost) {
    return put('/tuning-rule', { id, service, isActive, name, vendors, products, isThreshold, threshold, length, width, height, weight, dimWeight, cities, cost });
  },
  reorderTuningRules (service, rules) {
    return post('/tuning-rule/order', { service, rules });
  },
  fetchTuningRules (service) {
    return get(`/tuning-rule/service/${service}`);
  },
  deleteTuningRule (id) {
    return del(`/tuning-rule/${id}`);
  },
  fetchZoneTariffRuleDeliveryIntervals (coid) {
    return get(`/transport-company/zone-tariff-delivery-interval?coid=${coid}`);
  },
  fetchZoneTariffRules (tcid, coid) {
    return get(`/transport-company/zone-tariff-rule?tcid=${tcid}&coid=${coid}`);
  },
  putZoneTariffRule (zoneTariffRule) {
    return put('/transport-company/zone-tariff-rule', zoneTariffRule);
  },
  deleteZoneTariffRule (ruleId) {
    return del(`/transport-company/zone-tariff-rule/${ruleId}`);
  },
  fetchClients () {
    return get('/client');
  },
  putClient (client) {
    return put('/client', client);
  },
  fetchCompanies () {
    return get('/company');
  },
  putCompany (id, label, legalId) {
    return put('/company', { id, label, legalId });
  },
  fetchLegals () {
    return get('/legal');
  },
  putLegal (id, label) {
    return put('/legal', { id, label });
  },
  getProductVendorOptions () {
    return get('/vendor-options');
  },
  fetchTpZones () {
    return get('/zone');
  },
  fetchRegions () {
    return get('/region');
  },
  fetchCache (origin, destination) {
    return get(`/cache/${origin}/${destination}`);
  }
};
