<template>
  <form @submit.prevent="update">
    <b-form-group
      label="Идентификатор"
      label-cols-sm="4"
      label-for="legal-id"
    >
      <b-form-input
        id="legal-id"
        v-model="id"
        :disabled="!canEditId"
        placeholder="Идентификатор"
      />
    </b-form-group>
    <b-form-group
      label="Название"
      label-cols-sm="4"
      label-for="input-company"
    >
      <b-form-input
        id="input-company"
        v-model="label"
        placeholder="Название"
      />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
        type="submit"
        variant="info"
      >
        Сохранить
      </b-button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'LegalsForm',
  props: {
    item: {
      type: Object,
      required: true
    },
    canEditId: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      id: this.item.id || '',
      label: this.item.label || ''
    };
  },

  methods: {
    update () {
      this.$emit('update', { id: this.id, label: this.label });
    }
  }
};
</script>
