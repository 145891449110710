function create (id, from, to, cost) {
  return {
    id: String(id),
    from: Number(from),
    to: Number(to),
    cost: Number(cost)
  };
}

export default {
  create
};
