<template>
  <b-modal
    :visible="show"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    :title="title"
    size="lg"
  >
    <slot />
    <template #modal-header>
      <div>{{ title }}</div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="showConfirmModal = !showConfirmModal"
      >
        ×
      </button>
      <b-modal
        :visible="showConfirmModal"
        hide-footer
        title="Выйти без сохранения?"
        @hide="showConfirmModal = false"
      >
        <div class="d-flex justify-content-between">
          <b-button
            variant="info"
            class="flex-grow-1 mr-2"
            @click="confirm"
          >
            Да
          </b-button>
          <b-button
            variant="info"
            @click="showConfirmModal = !showConfirmModal"
          >
            {{ cancelText }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showConfirmModal: false
    };
  },
  methods: {
    confirm () {
      this.showConfirmModal = false;
      this.$emit('close');
    }
  }
};
</script>
