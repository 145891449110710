<template>
  <div
    class="app-suggest"
    :class="classList"
  >
    <input
      ref="input"
      v-model="query"
      class="app-suggest__input"
      autocomplete="none"
      :placeholder="placeholder"
      @focus="onFocus"
      @input="inputListener"
    >
    <ul
      v-if="suggestVisible"
      ref="itemsContainer"
      class="app-suggest__items"
      :class="{ 'app-suggest__items--with-scroll': maxItemsCount }"
      :style="itemsStyles"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="app-suggest__item"
        :class="{'app-suggest__item--selected': index === currentItemIndex}"
        @click="submit(item)"
      >
        <span class="app-suggest__text">
          <template v-if="isQueryMatch(item.text)">
            <span class="highlighted">{{ item.text }}</span>
          </template>
          <template v-else>
            {{ item.text }}
          </template>
        </span>
        <span
          v-if="item.subtext"
          class="app-suggest__subtext"
        >{{ item.subtext }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import escapeRegExp from 'lodash/escapeRegExp';

export default {
  name: 'AppSuggest',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default () {
        return [
          {
            id: -1,
            text: 'Не выбрано',
            value: '',
            subtext: ''
          }
        ];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxItemsCount: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      query: this.value || '',
      currentItemIndex: -1,
      hasFocus: false
    };
  },
  computed: {
    classList () {
      return {
        'app-suggest--focus': this.hasFocus,
        'app-suggest--disabled': this.disabled
      };
    },
    itemsStyles () {
      const style = {};
      if (this.maxItemsCount) { style.maxHeight = this.maxItemsCount * 32 + 12 + 'px'; }
      return style;
    },
    suggestVisible () {
      return this.items.length > 0 && this.hasFocus;
    }
  },
  watch: {
    items () {
      this.currentItemIndex = -1;
    },
    value (value) {
      if (value === null) {
        this.query = '';
      } else {
        this.query = value;
      }
    }
  },
  mounted () {
    document.addEventListener('mousedown', this.mousedownListener);
    this.$el.addEventListener('keydown', this.keyControl);
  },
  methods: {
    onFocus () {
      this.hasFocus = true;
      this.$emit('focus');
    },
    inputListener () {
      this.currentInputValue = this.query;
      this.$emit('input', this.query);
    },
    submit (item) {
      this.select(item, true);
      this.$emit('submit', item);
      this.$emit('change', item);

      this.currentItemIndex = -1;
      this.hasFocus = false;
      this.$refs.input.blur();
    },
    select (item) {
      this.$emit('select', item);
      this.query = item.text;
    },
    isQueryMatch (text) {
      if (this.query === '') {
        return false;
      }
      return (new RegExp(escapeRegExp(this.query), 'gi')).test(text);
    },
    mousedownListener (event) {
      // при клике вне элемента закрываем Select
      if (!this.$el.contains(event.target)) {
        this.hasFocus = false;
      }
    },
    keyControl (e) {
      if (this.items.length === 0) {
        return;
      }
      switch (e.key) {
        case 'ArrowUp':
        case 'Up':
          e.preventDefault();
          this.selectMoveUp();
          break;
        case 'ArrowDown':
        case 'Down':
        case 'Tab':
          e.preventDefault();
          this.selectMoveDown();
          break;
        case 'Enter':
          this.selectEnter();
          break;
        default:
      }
    },
    selectMoveUp () {
      if (this.currentItemIndex === -1) {
        this.currentItemIndex = this.items.length - 1;
        this.select(this.items[this.currentItemIndex]);
        return;
      }

      if (this.currentItemIndex === 0) {
        this.currentItemIndex = -1;
        this.unselect();
        return;
      }

      this.currentItemIndex -= 1;
      this.select(this.items[this.currentItemIndex]);
    },
    selectMoveDown () {
      if (this.currentItemIndex === this.items.length - 1) {
        this.currentItemIndex = -1;
        this.unselect();
        return;
      }

      this.currentItemIndex += 1;
      this.select(this.items[this.currentItemIndex]);
    },
    selectEnter () {
      if (this.currentItemIndex >= 0) {
        this.submit(this.items[this.currentItemIndex]);
      } else if (this.items[0]) {
        this.submit(this.items[0]);
      }
    },
    unselect () {
      this.query = this.currentInputValue;
      this.$emit('select', null);
    }
  }
};
</script>

<style lang="scss">
.app-suggest {
  cursor: pointer;
  position: relative;

  &__input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &:focus {
      color: #495057;
      border-color: #80bdff;
      outline: 0;
    }
  }

  &__items {
    z-index: 10;
    position: absolute;
    width: 100%;
    background-color: white;
    backdrop-filter: blur(9px);
    list-style: none;
    border-radius: 4px;
    padding: 0;
    border: 1px solid #80bdff;
    margin: 4px 0 0;
  }

  &__items--with-scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
      background: #E7E7E7;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 7px;
      background-color: #C9C9C9;
    }
  }

  &__item {
    padding: 0 0 0 12px;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      color: #fff;
      background-color: #80bdff;

      .app-suggest__text .highlighted {
        color: white;
      }
    }

    &--selected {
      color: #fff;
      background-color: #80bdff;
    }
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;

    .highlighted {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__subtext {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 12px;
    margin-left: 8px;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
}
</style>
