<template>
  <b-tab
    title="Регионы"
    href="#regions"
  >
    <b-card-text>
      <b-row>
        <b-col />
        <b-col
          cols="3"
          class="pr-2 pb-2 text-right"
        >
          Всего записей: {{ itemsTotal }}
        </b-col>
      </b-row>
      <b-table
        :fields="fields"
        :items="items"
        outlined
      />
    </b-card-text>
  </b-tab>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RegionsTab',
  data () {
    return {
      fields: [
        { key: 'fias', label: 'ФИАС', thClass: 'col-md-3' },
        { key: 'code', label: 'Код', thClass: 'col-md-1' },
        { key: 'label', label: 'Название' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: 'objects/regions',
      itemsTotal: 'objects/regionsTotal'
    })
  },
  async created () {
    await this.loadRegions();
  },
  methods: {
    ...mapActions({
      loadRegions: 'objects/loadRegions'
    })
  }
};
</script>
