<template>
  <b-icon-pencil-square
    class="component-class"
    @click="handler"
  />
</template>

<script>
import { BIconPencilSquare } from 'bootstrap-vue';

export default {
  name: 'EditIcon',
  components: {
    BIconPencilSquare
  },
  props: {
    handler: { type: Function, required: true }
  }
};
</script>

<style scoped>
.component-class {
  cursor: pointer;
}
</style>
