import Sortable from 'sortablejs/modular/sortable.core.esm.js';

export default {
  inserted (el, binding) {
    const settings = binding.value;
    const element = settings.containerSelector ? el.querySelector(settings.containerSelector) : el;

    Sortable.create(element, {
      handle: '.' + settings.handleClass,
      animation: 150,
      onEnd: settings.dropCallback
    });
  }
};
