import api from '@/api';

const state = {
  localityOptions: [],
  productOptions: [],
  vendorOptions: [],
  tariffs: [],
  transComps: [],
  warehouses: [],
  clients: [],
  companies: [],
  legals: [],
  regions: [],
  regionsTotal: 0,
  deliveryIntervals: {}
};

const getters = {
  localityOptions: state => {
    return state.localityOptions;
  },
  productOptions: state => {
    return state.productOptions;
  },
  vendorOptions: state => {
    return state.vendorOptions;
  },
  tariffs: state => {
    return state.tariffs;
  },
  transportCompanies: state => {
    return state.transComps;
  },
  warehouses: state => {
    return state.warehouses;
  },
  clients: state => {
    return state.clients;
  },
  companies: state => {
    return state.companies;
  },
  legals: state => {
    return state.legals;
  },
  regions: state => {
    return state.regions;
  },
  regionsTotal: state => {
    return state.regionsTotal;
  },
  deliveryIntervals: state => {
    return state.deliveryIntervals;
  }
};

const mutations = {
  LOAD_LOCALITY_OPTIONS_SUCCESS (state, payload) {
    state.localityOptions = payload;
  },
  LOAD_PRODUCT_OPTIONS_SUCCESS (state, payload) {
    state.productOptions = payload;
  },
  LOAD_VENDOR_OPTIONS_SUCCESS (state, payload) {
    state.vendorOptions = payload;
  },
  LOAD_TARIFFS_SUCCESS (state, payload) {
    state.tariffs = payload;
  },
  LOAD_TRANS_COMPS_SUCCESS (state, payload) {
    state.transComps = payload;
  },
  LOAD_WAREHOUSES_SUCCESS (state, payload) {
    state.warehouses = payload;
  },
  LOAD_CLIENTS_SUCCESS (state, payload) {
    state.clients = payload;
  },
  LOAD_COMPANIES_SUCCESS (state, payload) {
    state.companies = payload;
  },
  PUT_COMPANY_SUCCESS (state, payload) {
    state.companies.push(payload);
  },
  LOAD_LEGALS_SUCCESS (state, payload) {
    state.legals = payload;
  },
  LOAD_REGIONS_SUCCESS (state, payload) {
    state.regions = payload.result;
    state.regionsTotal = payload.rowsCount;
  },
  LOAD_DELIVERY_INTERVALS_SUCCESS (state, payload) {
    state.deliveryIntervals[payload.coid] = payload.data;
  }
};

const actions = {
  loadLocalityOptions ({ commit, state }) {
    if (state.localityOptions.length > 0) {
      return;
    }
    return api.getLocalityOptions()
      .then(response => commit('LOAD_LOCALITY_OPTIONS_SUCCESS', response.data))
      .catch(error => console.error('load locality options error', error));
  },
  loadProductOptions ({ commit, state }) {
    if (state.productOptions.length > 0) {
      return;
    }
    return api.getProductOptions()
      .then(response => commit('LOAD_PRODUCT_OPTIONS_SUCCESS', response.data))
      .catch(error => console.error('load product options error', error));
  },
  loadVendorOptions ({ commit, state }) {
    if (state.vendorOptions.length > 0) {
      return;
    }
    return api.getProductVendorOptions()
      .then(response => commit('LOAD_VENDOR_OPTIONS_SUCCESS', response.data))
      .catch(error => console.error('load vendor options error', error));
  },
  loadTariffs ({ commit }) {
    if (state.tariffs.length) {
      return;
    }
    return api.fetchTariffs()
      .then(response => commit('LOAD_TARIFFS_SUCCESS', response.data))
      .catch(error => console.error('load tariffs error', error));
  },
  loadTransportCompanies ({ commit }) {
    if (state.transComps.length) {
      return;
    }
    return api.fetchTransportCompanies()
      .then(response => commit('LOAD_TRANS_COMPS_SUCCESS', response.data))
      .catch(error => console.error('load transport companies error', error));
  },
  loadWarehouses ({ commit }) {
    if (state.warehouses.length) {
      return;
    }
    return api.fetchWarehouses()
      .then(response => commit('LOAD_WAREHOUSES_SUCCESS', response.data))
      .catch(error => console.error('load warehouses error', error));
  },
  loadClients ({ commit }) {
    if (state.clients.length) {
      return;
    }
    return api.fetchClients()
      .then(response => commit('LOAD_CLIENTS_SUCCESS', response.data))
      .catch(error => console.error('load clients error', error));
  },
  loadCompanies ({ commit }) {
    if (state.companies.length) {
      return;
    }
    return api.fetchCompanies()
      .then(response => commit('LOAD_COMPANIES_SUCCESS', response.data))
      .catch(error => console.error('load companies error', error));
  },
  putCompany ({ commit }, payload) {
    return api.putCompany(payload.id, payload.label, payload.legalId)
      .then(() => commit('PUT_COMPANY_SUCCESS', payload))
      .catch(error => console.error('put company error', error));
  },
  loadLegals ({ commit }) {
    if (state.legals.length) {
      return;
    }
    return api.fetchLegals()
      .then(response => commit('LOAD_LEGALS_SUCCESS', response.data))
      .catch(error => console.error('load legals error', error));
  },
  loadRegions ({ commit }) {
    if (state.regions.length) {
      return;
    }
    return api.fetchRegions()
      .then(response => commit('LOAD_REGIONS_SUCCESS', response.data))
      .catch(error => console.error('load regions error', error));
  },
  loadDeliveryIntervals ({ commit }, coid) {
    if (Array.isArray(state.deliveryIntervals[coid]) && state.deliveryIntervals[coid].length) {
      return;
    }
    return api.fetchZoneTariffRuleDeliveryIntervals(coid)
      .then(response => commit('LOAD_DELIVERY_INTERVALS_SUCCESS', { data: response.data, coid }))
      .catch(error => console.error('load delivery intervals error', error));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
