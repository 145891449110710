import Axios from 'axios';
import Cookie from 'cookie';

import store from '@/store';

const prefix = '/auth/v1';
const loginPath = '/login';
const logoutPath = '/logout';
const refreshPath = '/refresh';
const authorizedPath = '/authorized';
const isOpenUrl = url => {
  return [
    prefix + loginPath,
    prefix + logoutPath,
    prefix + refreshPath
  ].includes(url);
};

const expCookieName = '_delivery_dbrd_exp';
const isAuthExpired = () => {
  const cookies = Cookie.parse(document.cookie || '');
  const exp = parseInt(cookies[expCookieName]);
  const now = Math.floor(Date.now() / 1000);

  return exp - now <= 0;
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const retry = (fn, delay, times) => new Promise((resolve, reject) => {
  return fn()
    .then(resolve)
    .catch(err => {
      if (times - 1 > 0) {
        return sleep(delay)
          .then(retry.bind(null, fn, delay, times - 1))
          .then(resolve)
          .catch(reject);
      }

      return reject(err);
    });
});
const isRefreshing = () => new Promise(resolve => {
  if (store.getters['auth/isRefreshing']) {
    throw new Error();
  }

  resolve();
});

const delayInMs = 500;
const attempts = 10;
Axios.interceptors.request.use(request => {
  if (isOpenUrl(request.url)) {
    return request;
  }

  if (isAuthExpired()) {
    if (store.getters['auth/isRefreshing']) {
      return retry(isRefreshing, delayInMs, attempts).then(() => request);
    } else {
      return store.dispatch('auth/refresh').then(() => request);
    }
  }

  return request;
});

Axios.interceptors.response.use(undefined, error => {
  return new Promise(() => {
    const response = error.response;

    if (response.status === 401) {
      return store.dispatch('auth/logout');
    }

    throw error;
  });
});

const get = (path) => Axios.get(prefix + path);
const post = (path, data) => Axios.post(prefix + path, data);

export default {
  login (login, password) {
    return post(loginPath, { login, password });
  },
  logout () {
    return get(logoutPath);
  },
  refresh () {
    return get(refreshPath);
  },
  authorized () {
    return get(authorizedPath);
  },

  axios: Axios
};
