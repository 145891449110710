<template>
  <b-form-input
    :value="value"
    :state="state"
    type="number"
    min="0"
    :placeholder="placeholder"
    class="component-text"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null },
    state: { type: Boolean },
    placeholder: { type: String, default: '' }
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
.component-text {
  font-size: 0.9rem;
}
</style>
