<template>
  <div>
    {{ data ? 'Да' : 'Нет' }}
    <edit-icon
      :handler="editHandler"
      class="mr-1"
    />
    <remove-icon :handler="removeHandler" />
  </div>
</template>

<script>
import EditIcon from '@/components/icons/EditIcon';
import RemoveIcon from '@/components/icons/RemoveIcon';

export default {
  name: 'ActionsCell',
  components: {
    RemoveIcon,
    EditIcon
  },
  props: {
    data: { type: Boolean, required: true },
    editHandler: { type: Function, required: true },
    removeHandler: { type: Function, required: true }
  }
};
</script>
