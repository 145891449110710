<template>
  <div class="zone-tariff-rules_time-intervals-section">
    <div class="font-weight-500">
      Временные интервалы
    </div>
    <time-interval-form
      :coid="coid"
      :skip="skip"
      @submit="add"
    />
    <div
      v-if="list.length"
      class="mt-2"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="d-flex align-items-center"
      >
        <span class="zone-tariff-rules_time-intervals-section__interval">С {{ item.from }} до {{ item.to }}</span>
        <span class="zone-tariff-rules_time-intervals-section__interval-cost">{{ item.cost/100 }} руб.</span>
        <b-icon-pencil-square
          :id="`timeInterval${index}`"
          class="pointer ml-2"
          tabindex="0"
        />
        <b-icon-trash
          class="pointer ml-2"
          @click="remove(index)"
        />
        <b-popover
          :target="`timeInterval${index}`"
          triggers="click blur"
        >
          <time-interval-form
            layout="column"
            :coid="coid"
            :skip="skip"
            :item="list[index]"
            @submit="edit($event, index)"
          />
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
import TimeIntervalForm from '@/pages/ZoneTariffRules/TimeIntervalForm.vue';

export default {
  name: 'TimeIntervalsSection',
  components: {
    TimeIntervalForm
  },
  props: {
    coid: { type: String, required: true },
    list: { type: Array, default: () => [] }
  },
  computed: {
    skip () {
      return this.list.map(item => item.id);
    }
  },
  methods: {
    add (item) {
      this.$emit('update', [...this.list, item]);
    },
    edit (item, idx) {
      const list = [...this.list];
      list[idx] = item;
      this.$emit('update', list);
      this.$root.$emit('bv::hide::popover', `timeInterval${idx}`);
    },
    remove (idx) {
      const list = this.list;
      list.splice(idx, 1);
      this.$emit('update', list);
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_time-intervals-section {
  &__interval {
    flex-basis: 242px;
  }
  &__interval-cost {
    flex-basis: 100px;
  }
}
</style>
