import constants from '@/constants';

const zeroIfNotNumber = value => {
  const v = parseInt(value, 10);
  return isNaN(v) ? 0 : v;
};

const TuningRule = function (id, service, isActive, name, vendors, products, isThreshold, threshold, length, width, height, weight, dimWeight, cities, cost) {
  if (products.length === 1 && products[0].id === constants.WILDCARD_ID) {
    products[0].title = constants.WILDCARD_TITLE;
  }
  if (products.length === 1 && products[0].id === constants.PRODUCT_LIMITS_ID) {
    products[0].title = constants.PRODUCT_LIMITS_TITLE;
  }
  if (cities.length === 1 && cities[0].id === constants.WILDCARD_ID) {
    cities[0].title = constants.WILDCARD_TITLE;
  }
  this.id = id;
  this.service = service;
  this.isActive = isActive;
  this.name = name;
  this.vendors = [...vendors];
  this.products = [...products];
  this.isThreshold = isThreshold;
  this.threshold = threshold;
  this.length = length;
  this.width = width;
  this.height = height;
  this.weight = weight;
  this.dimWeight = dimWeight;
  this.cities = [...cities];
  this.cost = cost;
  this.idToDisplay = () => {
    return id.split('-')[4]; // the last part of UUID v4
  };
};

export default {
  newTuningRule (id, service, isActive, name, vendors, products, isThreshold, threshold, length, width, height, weight, dimWeight, cities, cost) {
    return new TuningRule(id, service, isActive, name.trim(), vendors, products, isThreshold, zeroIfNotNumber(threshold), zeroIfNotNumber(length), zeroIfNotNumber(width), zeroIfNotNumber(height), zeroIfNotNumber(weight), zeroIfNotNumber(dimWeight), cities, zeroIfNotNumber(cost));
  },
  isTuningRule (item) {
    return item instanceof TuningRule;
  }
};
