<template>
  <b-form
    inline
    class="component-form"
  >
    <b-container fluid>
      <b-row align-v="center">
        <b-col cols="3">
          Калькулятор разбиений
        </b-col>
        <b-col>
          <b-form-input
            v-model="volume"
            placeholder="Объем (м3)"
            size="sm"
            class="component-input"
          />
        </b-col>
        <b-col cols="5">
          <b-form-input
            v-model="length"
            placeholder="Длина (см)"
            size="sm"
            class="component-input"
          />
          <b-form-input
            v-model="width"
            placeholder="Ширина (см)"
            size="sm"
            class="component-input"
          />
          <b-form-input
            v-model="height"
            placeholder="Высота (см)"
            size="sm"
            class="component-input"
          />
        </b-col>
        <b-col class="component-text">
          {{ calculatedVolume }} ({{ calculationError }})
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import calculator from '@/helpers/lwh-calculator';

export default {
  name: 'CalculatorForm',
  data () {
    return {
      volume: '',
      length: '',
      width: '',
      height: ''
    };
  },
  computed: {
    calculatedVolume () {
      return calculator.volume(this.length, this.width, this.height);
    },
    calculationError () {
      return calculator.difference(this.volume, this.calculatedVolume);
    }
  }
};
</script>

<style scoped>
.component-form {
  max-width: 54rem;
}

.component-input {
  font-size: 0.8rem;
  width: 6rem;
}

.component-text {
  font-size: 0.8rem;
}
</style>
