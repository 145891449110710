<template>
  <top-menu />
</template>

<script>
import TopMenu from '@/components/TopMenu';

export default {
  name: 'HomeScreen',
  components: {
    TopMenu
  }
};
</script>
