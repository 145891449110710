<template>
  <div>
    <b-form-input
      :value="value"
      :state="state"
      placeholder=""
      class="d-inline-block component-class"
      aria-describedby="cost-error-feedback"
      @input="onInput"
    />
    <b-form-invalid-feedback id="cost-error-feedback">
      Стоимость должна быть целым числом
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: 'CostInput',
  props: {
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null },
    state: { type: Boolean }
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
.component-class {
  font-size: 0.9rem;
  width: 220px;
}
</style>
