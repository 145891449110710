import api from '@/api';
import tariffMapper from '@/helpers/tariff-mapper';
import objects from '@/store/objects';

const state = {
  companies: [],
  companyId: '',
  rules: [],
  transportCompanyId: '',
  zones: []
};

const getters = {
  activeZones (state) {
    return state.zones.filter(item => item.isActive && item.companyId === state.companyId);
  },
  companies (state) {
    return state.companies;
  },
  rules (state) {
    return state.rules;
  },
  zoneForId: state => id => {
    return state.zones.find(item => item.id === id);
  }
};

const actions = {
  async fetchCompanies ({ dispatch, getters, state }, allowedCompanies) {
    await dispatch('objects/loadCompanies', null, { root: true });
    const companies = getters['objects/companies'];
    if (allowedCompanies.includes('*')) {
      state.companies = companies;
    } else {
      state.companies = companies.filter(item => allowedCompanies.includes(item.id));
    }
  },
  async fetchRules ({ state }, params) {
    state.transportCompanyId = params.tcid;
    state.companyId = params.coid;
    state.zones = [];
    const zonesRes = await api.fetchTpZones();
    if (zonesRes.status === 200) {
      state.zones = zonesRes.data.map(item => {
        // добавляем поля для отображения в select-ах
        return { ...item, text: item.name, value: item.id };
      });
    }
    state.rules = [];
    const rulesRes = await api.fetchZoneTariffRules(params.tcid, params.coid);
    if (rulesRes.status === 200 && params.coid in rulesRes.data) {
      // добавляем названия тарифов и данные зоны
      state.rules = rulesRes.data[params.coid].map(item => {
        tariffMapper.appendNames(item.tariffs);
        return { ...item, zone: state.zones.find(zone => zone.id === item.zoneId) };
      });
    }
  }
};

const modules = {
  objects
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  modules
};
