<template>
  <form @submit.prevent="save">
    <b-form-group
      label="ID"
      label-cols-sm="3"
    >
      <b-form-input
        :value="item.id"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group
      label="Компания"
      label-cols-sm="3"
    >
      <b-select
        v-model="companyId"
        :options="companyOptions"
        class="mt-2"
      />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
        type="submit"
        variant="info"
      >
        Сохранить
      </b-button>
    </div>
  </form>
</template>

<script>
import api from '@/api';
import { mapGetters, mapActions } from 'vuex';
import client from '@/objects/client';

export default {
  name: 'ClientsForm',
  props: {
    item: { type: Object, required: true }
  },
  data () {
    return {
      companyId: this.item.companyId || ''
    };
  },
  computed: {
    ...mapGetters({
      companies: 'objects/companies'
    }),
    companyOptions () {
      return [{ text: '', value: '' }, ...this.companies.map(item => {
        return { text: item.label, value: item.id };
      })];
    }
  },
  async created () {
    await this.fetchCompanies();
  },
  methods: {
    ...mapActions({
      fetchCompanies: 'objects/loadCompanies'
    }),
    async save () {
      try {
        await api.putClient(client.create(this.item.id, this.companyId));
        window.location.reload();
      } catch (e) {
        console.error(e.response);
        if (e.response.status === 400) {
          alert(e.response.data.message);
        }
      }
    }
  }
};
</script>
