function create (from, to, cost) {
  return {
    weightFrom: Number(from),
    weightTo: Number(to),
    cost: Number(cost)
  };
}

export default {
  create
};
