const weights = {
  technopark: 50,
  bork: 40,
  bork_retail: 35,
  laurastar: 30,
  bissel: 20,
  borkkz: 10
};

export default {
  sorted: (list) => {
    return list.sort((a, b) => {
      if (a.id in weights && b.id in weights) {
        if (weights[a.id] > weights[b.id]) { return -1; }
        if (weights[a.id] < weights[b.id]) { return 1; }
      }
      return 0;
    });
  }
};
