function create (id, companyId) {
  return {
    id,
    companyId
  };
}

export default {
  create
};
