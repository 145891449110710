import objects from '@/store/objects';

const state = {
  transportCompanies: []
};

const getters = {
  transportCompanies (state) {
    return state.transportCompanies;
  }
};

const actions = {
  async fetchTransportCompanies ({ dispatch, getters, state }) {
    await dispatch('objects/loadTransportCompanies', null, { root: true });
    const tcs = getters['objects/transportCompanies'];
    tcs.sort((a, b) => {
      if (a.label > b.label) { return 1; }
      if (a.label < b.label) { return -1; }
      return 0;
    });
    state.transportCompanies = tcs;
  }
};

const modules = {
  objects
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  modules
};
