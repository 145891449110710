<template>
  <b-form
    class="mb-3"
    inline
  >
    <div class="mr-2">
      Выбрать
    </div>
    <b-form-select
      v-model="filterKind"
      class="mb-2 mr-sm-2 mb-sm-0"
      :options="filterOptions"
    />
    <div v-if="filterKind === FILTER_KIND_LABEL">
      <b-form-input
        v-model="label"
        placeholder="похожим на"
      />
    </div>
    <div v-else-if="filterKind === FILTER_KIND_FIAS">
      <b-form-input
        v-model="fias"
        placeholder="ФИАС"
        class="component-input-fias"
      />
    </div>
    <div v-else-if="filterKind === FILTER_KIND_LEVEL">
      <b-form-input
        v-model="level"
        type="number"
        placeholder="уровень ФИАС"
        class="component-input-level"
      />
    </div>
    <div v-else-if="filterKind === FILTER_KIND_REGION">
      <b-form-input
        v-model="region"
        placeholder="с ФИАС"
        class="component-input-fias"
      />
    </div>
    <b-button
      variant="info"
      class="ml-2"
      @click="queryLocalities"
    >
      показать
    </b-button>
  </b-form>
</template>

<script>
import api from '@/api';

export default {
  name: 'LocalitiesFilterForm',
  data () {
    return {
      FILTER_KIND_LABEL: api.LOCALITY_FILTER_KIND_LABEL,
      FILTER_KIND_FIAS: api.LOCALITY_FILTER_KIND_FIAS,
      FILTER_KIND_LEVEL: api.LOCALITY_FILTER_KIND_LEVEL,
      FILTER_KIND_REGION: api.LOCALITY_FILTER_KIND_REGION,
      filterKind: api.LOCALITY_FILTER_KIND_LABEL,
      filterOptions: [
        { value: api.LOCALITY_FILTER_KIND_LABEL, text: 'с названием' },
        { value: api.LOCALITY_FILTER_KIND_FIAS, text: 'с ФИАС' },
        { value: api.LOCALITY_FILTER_KIND_LEVEL, text: 'с уровнем' },
        { value: api.LOCALITY_FILTER_KIND_REGION, text: 'в регионе' }
      ],
      label: '',
      fias: '',
      level: 4,
      region: ''
    };
  },
  methods: {
    queryLocalities () {
      switch (this.filterKind) {
        case this.FILTER_KIND_LABEL:
          api.filterLocalitiesWithLabel(this.filterKind, this.label).then(this.emitQueryResult);
          break;
        case this.FILTER_KIND_FIAS:
          api.filterLocalitiesWithFias(this.filterKind, this.fias).then(this.emitQueryResult);
          break;
        case this.FILTER_KIND_LEVEL:
          api.filterLocalitiesWithLevel(this.filterKind, Number(this.level)).then(this.emitQueryResult);
          break;
        case this.FILTER_KIND_REGION:
          api.filterLocalitiesWithRegion(this.filterKind, this.region).then(this.emitQueryResult);
          break;
        default:
          // do nothing
          break;
      }
    },
    emitQueryResult (res) {
      if (res.status === 200) {
        this.$emit('queryResult', res.data);
      }
    }
  }
};
</script>

<style scoped>
.component-input-fias {
  width: 21rem;
}
.component-input-level {
  width: 4rem;
}
</style>
