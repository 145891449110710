<template>
  <div class="h5 text-info component-class">
    <info-icon :id="id" />
    <b-popover
      :target="id"
      placement="bottom"
      title="Варианты ввода"
      triggers="hover focus"
    >
      <template #default>
        При подсчете стоимости доставки товара применяется одно правило с наивысшим приоритетом<br>
        <br>
        Отрицательная стоимость означает запрет перевозки для указанных параметров
      </template>
    </b-popover>
  </div>
</template>

<script>
import InfoIcon from '@/components/icons/InfoIcon';

export default {
  name: 'InfoPopover',
  components: {
    InfoIcon
  },
  props: {
    service: { type: String, required: true }
  },
  data () {
    return {
      id: this.service + '-rules'
    };
  }
};
</script>

<style scoped>
.component-class {
  display: inline-block;
}
</style>
