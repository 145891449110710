const COUNTRY_RUSSIA = 'RU';
const COUNTRY_KAZAKHSTAN = 'KZ';

export default {
  countryName (country) {
    switch (country) {
      case COUNTRY_RUSSIA:
        return 'Россия';
      case COUNTRY_KAZAKHSTAN:
        return 'Казахстан';
      default:
        return 'Неизвестная страна';
    }
  },
  currencyName (country) {
    switch (country) {
      case COUNTRY_RUSSIA:
        return 'руб';
      case COUNTRY_KAZAKHSTAN:
        return 'тенге';
      default:
        return 'единиц';
    }
  }
};
