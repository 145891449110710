import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/custom.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import store from '@/store';
import router from '@/router';
import App from '@/App.vue';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
