<template>
  <multiselect
    :value="value"
    track-by="id"
    label="title"
    :placeholder="placeholder"
    :options="options"
    :loading="isLoading"
    :searchable="searchable"
    :multiple="true"
    :hide-selected="true"
    :internal-search="false"
    select-label=""
    @select="onSelect"
    @input="onInput"
    @close="onClose"
    @search-change="asyncFind"
  >
    <template slot="noOptions">
      Начните вводить название элемента
    </template>
    <template slot="noResult">
      Похожих элементов не найдено
    </template>
  </multiselect>
</template>

<script>
import constants from '@/constants';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ItemSelector',
  components: {
    Multiselect
  },
  props: {
    kind: { type: String, required: true },
    value: { type: Array, required: true },
    placeholder: { type: String, required: true },
    searchable: { type: Boolean, default: true },
    optionsFilter: { type: Function, default: (currentValue, options) => options }
  },
  data () {
    return {
      options: [],
      isLoading: false
    };
  },
  methods: {
    onSelect () {
      this.options = [];
    },
    onInput (newValue) {
      this.$emit('input', newValue);
    },
    onClose () {
      this.options = [];
    },
    asyncFind (query) { // TODO: move outside, add a property that accepts a function
      if (query === '') {
        return;
      }
      query = query.toLowerCase();

      this.isLoading = true;

      switch (this.kind) {
        case constants.KIND_LOCALITY:
          this.$store.dispatch('objects/loadLocalityOptions').then(() => {
            this.options = this.optionsFilter(
              this.value,
              [
                { id: constants.WILDCARD_ID, title: constants.WILDCARD_TITLE }
              ].concat(this.$store.getters['objects/localityOptions'].filter(item => {
                return item.title.toLowerCase().includes(query);
              }))
            );
            this.isLoading = false;
          });
          break;
        case constants.KIND_PRODUCT:
          this.$store.dispatch('objects/loadProductOptions').then(() => {
            this.options = this.optionsFilter(
              this.value,
              [
                { id: constants.WILDCARD_ID, title: constants.WILDCARD_TITLE },
                { id: constants.PRODUCT_LIMITS_ID, title: constants.PRODUCT_LIMITS_TITLE }
              ].concat(this.$store.getters['objects/productOptions'].filter(item => {
                return item.title.toLowerCase().includes(query);
              }))
            );
            this.isLoading = false;
          });
          break;
        case constants.KIND_VENDOR:
          this.$store.dispatch('objects/loadVendorOptions').then(() => {
            this.options = this.optionsFilter(
              this.value,
              this.$store.getters['objects/vendorOptions'].filter(item => {
                return item.title.toLowerCase().includes(query);
              })
            );
            this.isLoading = false;
          });
          break;
        default:
          // Do nothing
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style>
.multiselect__select {
  display: none;
}
</style>
