<template>
  <div>
    {{ present ? data + ' руб.' : 'Нет' }}
  </div>
</template>

<script>
export default {
  name: 'CostCell',
  props: {
    present: { type: Boolean, default: true },
    data: { type: Number, required: true }
  }
};
</script>
