function create (id, transportCompanyId, companyId, zoneId, tariffs, isActive) {
  return {
    id,
    transportCompanyId,
    companyId,
    zoneId,
    tariffs,
    isActive
  };
}

export default {
  create
};
