<template>
  <div class="component-class fadeInDown">
    <div class="component-content text-center">
      <header class="modal-header">
        <h5 class="modal-title fadeIn first">
          Delivery Dashboard
        </h5>
      </header>

      <div class="modal-body mt-3">
        <b-form @submit="onSubmit">
          <b-form-input
            v-model="$v.login.$model"
            class="fadeIn second component-text mb-3"
            placeholder="Логин*"
            :state="validateState('login')"
          />
          <b-form-input
            v-model="$v.password.$model"
            type="password"
            class="fadeIn third component-text mb-3"
            placeholder="Пароль*"
            :state="validateState('password')"
          />
          <b-alert
            :show="isError()"
            variant="danger"
          >
            {{ error }}
          </b-alert>
          <b-button
            type="submit"
            variant="info"
            class="fadeIn fourth"
          >
            Войти
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'LoginScreen',
  mixins: [validationMixin],
  data () {
    return {
      login: '',
      password: '',
      error: ''
    };
  },
  validations: {
    login: { required },
    password: { required }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v[name];

      return $dirty ? !$error : null;
    },
    isError () {
      return this.error !== '';
    },
    onSubmit (event) {
      event.preventDefault();
      event.stopPropagation();

      this.error = '';

      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.$store.dispatch('auth/login', { login: this.login, password: this.password }).then(() => {
        if (this.$store.getters['auth/isInvalidCredentials']) {
          this.error = 'Неверный логин/пароль';

          return;
        }

        if (this.$store.getters['auth/isAuthorized']) {
          this.$router.push({ name: 'home' });
        }
      });
    }
  }
};
</script>

<style scoped>
.component-class {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 28px;
}

.component-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  width: 498px;
}

.component-text {
  font-size: 0.9rem;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
</style>
