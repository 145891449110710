<template>
  <div class="component-class text-center">
    <div class="component-placeholder align-middle h1">
      <span class="pr-2">404 Not Found</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
};
</script>

<style scoped>
.component-class {
  display: table;
  height: 90vh;
  width: 100%;
}

.component-placeholder {
  display: table-cell;
}

.component-placeholder > span {
  animation: type .5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0 0 #212529;
  }
  to {
    box-shadow: inset -3px 0 0 transparent;
  }
}
</style>
