<template>
  <b-tab
    title="Клиенты"
    href="#clients"
  >
    <b-card-text>
      <b-table
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(companyId)="data">
          {{ companiesMap[data.item.companyId] || "Неизвестная компания" }}
        </template>
        <template #cell(edit)="data">
          <edit-cell :title="`Редактирование клиента ${data.item.id}`">
            <clients-form :item="data.item" />
          </edit-cell>
        </template>
      </b-table>
    </b-card-text>
  </b-tab>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditCell from '@/pages/AdminArea/EditCell';
import ClientsForm from '@/pages/AdminArea/ClientsTab/ClientsForm';

export default {
  name: 'ClientsTab',
  components: {
    EditCell,
    ClientsForm
  },
  data () {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'companyId', label: 'Компания' },
        { key: 'edit', label: '' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: 'objects/clients',
      companies: 'objects/companies'
    }),
    companiesMap () {
      return this.companies.reduce((acc, item) => ({ ...acc, [item.id]: item.label }), {});
    }
  },
  async created () {
    await this.fetchCompanies();
    await this.fetchClients();
  },
  methods: {
    ...mapActions({
      fetchClients: 'objects/loadClients',
      fetchCompanies: 'objects/loadCompanies'
    })
  }
};
</script>
