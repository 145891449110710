<template>
  <item-selector
    :value="value"
    placeholder=""
    :kind="KIND_VENDOR"
    @input="onInput"
  />
</template>

<script>
import constants from '@/constants';
import ItemSelector from '@/pages/TuningRules/RuleModal/ItemSelector';

export default {
  name: 'VendorSelector',
  components: {
    ItemSelector
  },
  props: {
    value: { type: Array, required: true }
  },
  data () {
    return {
      KIND_VENDOR: ''
    };
  },
  created () {
    this.KIND_VENDOR = constants.KIND_VENDOR;
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>
