<template>
  <div>
    <item-selector
      :value="value"
      placeholder=""
      :kind="KIND_LOCALITY"
      :searchable="searchable"
      :options-filter="filterCallback()"
      @input="onInput"
    />
    <b-form-invalid-feedback :state="state">
      Список населенных пунктов не может быть пустым
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import constants from '@/constants';
import ItemSelector from '@/pages/TuningRules/RuleModal/ItemSelector';

export default {
  name: 'CitySelector',
  components: {
    ItemSelector
  },
  props: {
    value: { type: Array, required: true },
    state: { type: Boolean }
  },
  data () {
    return {
      KIND_LOCALITY: '',
      searchable: !this.isWildcardOptionChosen(this.value)
    };
  },
  created () {
    this.KIND_LOCALITY = constants.KIND_LOCALITY;
  },
  methods: {
    onInput (newValue) {
      this.searchable = !this.isWildcardOptionChosen(newValue);
      this.$emit('input', newValue);
    },
    filterCallback () {
      return (currentValue, options) => {
        if (currentValue.length > 0) {
          options = this.filterOutWildcardOption(options);
        }
        return options;
      };
    },
    isWildcardOptionChosen (value) {
      return (value.length === 1 && value[0].id === constants.WILDCARD_ID);
    },
    filterOutWildcardOption (options) {
      return options.filter(item => item.id !== constants.WILDCARD_ID);
    }
  }
};
</script>
