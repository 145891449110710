<template>
  <AppSuggest
    placeholder="Выберите интервал"
    :value="selected"
    :items="options"
    @input="filterItems"
    @change="onChange"
  />
</template>

<script>
import AppSuggest from '@/components/AppSuggest';

export default {
  name: 'DeliveryIntervalSelector',
  components: {
    AppSuggest
  },
  props: {
    coid: { type: String, required: true },
    skip: { type: Array, required: true },
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null }
  },
  data () {
    return {
      selected: null,
      deliveryIntervals: [],
      query: ''
    };
  },
  computed: {
    options () {
      return this.deliveryIntervals
        .filter(item => !this.skip.includes(item.id))
        .map(item => ({ value: item.id, text: 'С ' + item.from + ' до ' + item.to }))
        .filter(item => this.query === '' ? item : item.text?.toLowerCase().includes(this.query?.toLowerCase()));
    }
  },
  watch: {
    value (newValue) {
      if (newValue === null) {
        this.selected = null;
      } else {
        this.selected = this.options.find(item => item.value === newValue).text;
      }
    }
  },
  created () {
    this.$store.dispatch('objects/loadDeliveryIntervals', this.coid).then(() => {
      this.deliveryIntervals = this.$store.getters['objects/deliveryIntervals'][this.coid];
    });
  },
  methods: {
    filterItems (query) {
      this.query = query;
      if (query === '') {
        this.$emit('input', null);
      }
    },
    onChange (value) {
      this.query = '';
      this.$emit('input', value.value);
    }
  }
};
</script>
