<template>
  <div>
    <span class="font-weight-500">
      Тарифы
    </span>
    <b-list-group class="mt-3">
      <b-list-group-item
        v-for="item in list"
        :key="item.code"
      >
        <span :is="item.isActive ? 'span' : 'del'">{{ item.name }}</span>
        <b-icon-pencil-square
          class="ml-3 pointer"
          @click="edit(item.code)"
        />
        <b-icon-trash
          class="ml-3 pointer"
          @click="remove(item.code)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button
      v-if="isAddAllowed"
      class="mt-2"
      size="sm"
      variant="info"
      @click="add"
    >
      Новый тариф
    </b-button>
    <b-collapse
      v-model="isEdit"
      class="zone-tariff-rules_tariffs-section__collapse"
    >
      <form @submit.prevent="save">
        <div
          v-if="type === 'edit'"
          class="font-weight-500"
        >
          Редактирование тарифа "{{ tariffNameForCode(code) }}"
        </div>
        <template v-else-if="type === 'add'">
          <div class="font-weight-500">
            Добавление нового тарифа
          </div>
          <hr class="zone-tariff-rules_tariffs-section__hr">
          <div class="font-weight-500">
            Выберите тариф*
          </div>
          <b-select
            v-model="code"
            :options="tariffOptions"
            class="mt-2"
          />
        </template>
        <hr class="zone-tariff-rules_tariffs-section__hr">
        <b-checkbox v-model="isActive">
          Активен
        </b-checkbox>
        <weights-form
          v-if="code"
          :costs="costs"
          :weights="weights"
          :intervals="intervals"
          @update="weightIntervalsUpdated"
        />
        <template v-if="intervals.length">
          <hr class="zone-tariff-rules_tariffs-section__hr">
          <time-intervals-section
            :coid="coid"
            :list="timeIntervals"
            @update="timeIntervalsUpdated"
          />
          <hr class="zone-tariff-rules_tariffs-section__hr">
          <div class="zone-tariff-rules_tariffs-section__days">
            <div class="d-flex align-items-center">
              <span class="mr-2 flex-shrink-0">Кол-во дней доставки *</span>
              <b-input
                v-model="days"
                type="number"
                size="sm"
                autocomplete="off"
                min="0"
                max="30"
                step="0"
              />
            </div>
            <div class="d-flex align-items-center">
              <span class="mr-2 flex-shrink-0">Кол-во часов доставки *</span>
              <b-input
                v-model="hours"
                type="number"
                size="sm"
                autocomplete="off"
                min="0"
                max="23"
                step="1"
              />
            </div>
            <div
              v-if="isSpecialSettingsAvailable(code)"
              class="d-flex align-items-center"
            >
              <span class="mr-2 flex-shrink-0">Перенос на след. день c</span>
              <b-icon
                id="timeLimitInfo"
                tabindex="-1"
                icon="exclamation-circle-fill"
                variant="info"
                class="mr-2"
              />
              <b-tooltip target="timeLimitInfo">
                Указывается в часах по Московскому времени
              </b-tooltip>
              <b-input
                v-model="timeLimit"
                type="number"
                size="sm"
                autocomplete="off"
                min="0"
                max="23"
                step="1"
              />
            </div>
            <div
              v-if="isSpecialSettingsAvailable(code)"
              class="d-flex align-items-center"
            >
              <span class="mr-2 flex-shrink-0">Смещение даты отгрузки, в днях</span>
              <b-input
                v-model="shipmentDays"
                type="number"
                size="sm"
                autocomplete="off"
                min="0"
                max="31"
                step="1"
              />
            </div>
          </div>
          <div class="zone-tariff-rules_tariffs-section__wh">
            <div class="d-flex align-items-center">
              <span class="mr-2 flex-shrink-0">Кастомный склад отгрузки</span>
              <b-form-input
                v-model="customWarehouse"
                size="sm"
              />
            </div>
          </div>
        </template>
        <template v-if="code">
          <hr>
          <b-form-group
            label="Дни недели, в которые возможна доставка:"
          >
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="daysOfWeekEnabled"
              :options="daysOfWeekOptions"
            />
          </b-form-group>
        </template>
        <template v-if="code">
          <hr class="zone-tariff-rules_tariffs-section__hr">
          <special-costs-section
            :list="specialCosts"
            @update="specialCostsUpdated"
            @collapse="isSpecialCostsEdit = !isSpecialCostsEdit"
          />
        </template>
        <div
          v-if="!isSpecialCostsEdit"
          class="d-flex justify-content-center mt-3"
        >
          <b-button
            v-if="intervals.length"
            class="mr-2"
            variant="info"
            size="sm"
            type="submit"
          >
            Сохранить
          </b-button>
          <b-button
            variant="light"
            size="sm"
            @click="cancel"
          >
            Отменить
          </b-button>
        </div>
      </form>
    </b-collapse>
  </div>
</template>

<script>
import hoursTransformer from '@/helpers/hours-transformer';
import tariffMapper from '@/helpers/tariff-mapper';
import tcMapper from '@/helpers/tc-mapper';
import zoneTariff from '@/objects/zone-tariff';
import zoneTariffWeightCosts from '@/objects/zone-tariff-weight-costs';
import WeightsForm from '@/pages/ZoneTariffRules/WeightsForm.vue';
import TimeIntervalsSection from '@/pages/ZoneTariffRules/TimeIntervalsSection.vue';
import SpecialCostsSection from '@/pages/ZoneTariffRules/SpecialCostsSection.vue';

const TIMEZONE_MSK = 3;

export default {
  name: 'TariffsSection',
  components: {
    WeightsForm,
    TimeIntervalsSection,
    SpecialCostsSection
  },
  props: {
    tcid: { type: String, required: true },
    coid: { type: String, required: true },
    list: { type: Array, required: true }
  },
  data () {
    return {
      type: null,
      isEdit: false,
      code: null,
      isActive: false,
      costs: [],
      weights: [],
      intervals: [],
      timeIntervals: [],
      days: 0,
      hours: 0,
      timeLimit: 0,
      shipmentDays: 0,
      customWarehouse: '',
      daysOfWeekEnabled: [0, 1, 2, 3, 4, 5, 6],
      daysOfWeekOptions: [
        { text: 'Понедельник', value: 1 },
        { text: 'Вторник', value: 2 },
        { text: 'Среда', value: 3 },
        { text: 'Четверг', value: 4 },
        { text: 'Пятница', value: 5 },
        { text: 'Суббота', value: 6 },
        { text: 'Воскресенье', value: 0 }
      ],
      specialCosts: [],
      isSpecialCostsEdit: false
    };
  },
  computed: {
    isAddAllowed () {
      if (this.list.length > 0) {
        return this.list.length < tariffMapper.getTcTariffsCount(this.tcid) && !this.isEdit;
      } else {
        return !this.isEdit;
      }
    },
    tariffOptions () {
      return tariffMapper.getTariffOptionsByTcId(this.tcid).filter(item => !this.list.map(item => item.code).includes(item.value));
    }
  },
  watch: {
    code (newValue) {
      const tariff = this.list.find(item => item.code === newValue);
      if (tariff) {
        this.isActive = tariff.isActive;
        this.costs = tariff.cost.costs;
        this.weights = [0, ...tariff.cost.weights];
        this.intervals = tariff.cost.costs.map((cost, index) => { return { cost, weightFrom: this.weights[index] / 1000, weightTo: this.weights[index + 1] / 1000 }; });
        this.timeIntervals = tariff.intervals;
        this.days = tariff.days;
        this.hours = tariff.hours;
        this.timeLimit = hoursTransformer.fromUtc(tariff.timeLimit, TIMEZONE_MSK);
        this.shipmentDays = tariff.shipmentDays;
        this.customWarehouse = tariff.customWarehouse;
        this.daysOfWeekEnabled = tariff.daysOfWeekEnabled === null ? [] : tariff.daysOfWeekEnabled;
        this.specialCosts = tariff.specialCosts;
      } else {
        this.isActive = false;
        this.costs = [];
        this.weights = [];
        this.intervals = [];
        this.timeIntervals = [];
        this.days = 0;
        this.hours = 0;
        this.timeLimit = 0;
        this.shipmentDays = 0;
        this.customWarehouse = '';
        this.daysOfWeekEnabled = [0, 1, 2, 3, 4, 5, 6];
        this.specialCosts = [];
      }
    }
  },
  methods: {
    add () {
      this.type = 'add';
      this.isEdit = true;
      this.code = null;
      this.$emit('collapse');
    },
    edit (code) {
      this.type = 'edit';
      this.isEdit = true;
      this.code = code;
      this.$emit('collapse');
    },
    remove (code) {
      const idx = this.list.findIndex(item => item.code === code);
      const copy = [...this.list];
      copy.splice(idx, 1);
      this.$emit('update', copy);
    },
    save () {
      if (this.intervals.length === 1 && Boolean(this.intervals[0].weightTo)) {
        alert('Весовые интервалы заполнены некорректно. Укажите конечную весовую границу');
        return;
      }
      if ((this.days !== 0 && !this.days) || (this.hours !== 0 && !this.hours) || !this.timeIntervals.length) {
        alert('Заполнены не все данные');
        return;
      }
      const item = zoneTariff.create(this.code, this.days, this.hours, hoursTransformer.toUtc(this.timeLimit, TIMEZONE_MSK), this.shipmentDays, this.customWarehouse, this.timeIntervals, zoneTariffWeightCosts.create(this.costs, this.weights), this.specialCosts, this.daysOfWeekEnabled, this.isActive);
      if (this.type === 'add') {
        this.$emit('update', [...this.list, item]);
      } else if (this.type === 'edit') {
        const idx = this.list.findIndex(item => item.code === this.code);
        const copy = [...this.list];
        copy[idx] = item;
        this.$emit('update', copy);
      }
      this.code = null;
      this.type = null;
      this.isEdit = false;
      this.$emit('collapse');
    },
    tariffNameForCode (code) {
      return tariffMapper.getTariffNameByCode(code);
    },
    weightIntervalsUpdated (data) {
      this.intervals = data.intervals;
      this.costs = data.costs;
      this.weights = data.weights;
    },
    timeIntervalsUpdated (data) {
      this.timeIntervals = data;
    },
    isSpecialSettingsAvailable (code) {
      return (this.tcid !== tcMapper.TCID_DPD && !tariffMapper.isSameDayDeliveryTariff(code));
    },
    specialCostsUpdated (data) {
      this.specialCosts = data;
    },
    cancel () {
      this.isEdit = false;
      this.$emit('collapse');
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_tariffs-section {
  &__collapse {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px;
    margin-top: 8px;
  }
  &__hr {
    border-style: dashed;
    border-color: rgba(0, 0, 0, .6);
    width: 100%;
    margin: 12px 0;
  }
  &__days {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  &__days > div {
    flex-grow: 1;
    width: 33%;
  }
  &__wh {
    margin-top: 8px;
  }
}
</style>
