<template>
  <ul class="dimensions-cell">
    <li v-if="data.length">
      Длина: {{ data.length }} см
    </li>
    <li v-if="data.width">
      Ширина: {{ data.width }} см
    </li>
    <li v-if="data.height">
      Высота: {{ data.height }} см
    </li>
    <li v-if="data.weight">
      Вес: {{ data.weight }} кг
    </li>
    <li v-if="data.dimWeight">
      Объемный вес: {{ data.dimWeight }} кг
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DimensionsCell',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss">
.dimensions-cell {
  padding: 0;
  list-style-type: circle;
}
</style>
