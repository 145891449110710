<template>
  <b-tab
    title="Тарифы"
    href="#tariffs"
  >
    <b-card-text>
      <b-form
        class="mb-3"
        inline
      >
        <div class="mr-2">
          Фильтр по ТК
        </div>
        <b-form-select
          v-model="filter"
          class="mb-2 mr-sm-2 mb-sm-0"
          :options="filterOptions"
        />
      </b-form>
      <b-table
        :filter="filter"
        :filter-included-fields="filterOn"
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(delivery_time_options)="row">
          <ul
            v-if="hasDeliveryOptions(row.item.delivery_time_options)"
            class="component-list"
          >
            <li
              v-for="opt in row.item.delivery_time_options"
              :key="opt.time_period"
            >
              {{ opt.time_period }}<span v-if="opt.description"> ({{ opt.description }})</span>
            </li>
          </ul>
          <div
            v-else
            class="text-center"
          >
            нет
          </div>
        </template>
        <template #cell(cod_limits)="row">
          <ul
            v-if="hasCodLimits(row.item.cod_limits)"
            class="component-list"
          >
            <li
              v-for="(limit, country) in row.item.cod_limits"
              :key="country"
            >
              {{ countryName(country) }}: {{ limit / 100 }} {{ currencyName(country) }}
            </li>
          </ul>
          <div
            v-else
            class="text-center"
          >
            нет
          </div>
        </template>
        <template #cell(package_limits)="row">
          <ul
            v-if="hasPhysicalLimits(row.item.package_limits)"
            class="component-list"
          >
            <li v-if="row.item.package_limits.length">
              длина: {{ row.item.package_limits.length }} см
            </li>
            <li v-if="row.item.package_limits.width">
              ширина: {{ row.item.package_limits.width }} см
            </li>
            <li v-if="row.item.package_limits.height">
              высота: {{ row.item.package_limits.height }} см
            </li>
            <li
              v-if="row.item.package_limits.lwh_sum"
              class="text-nowrap"
            >
              сумма трех измерений:
              {{ row.item.package_limits.lwh_sum }} см
            </li>
            <li v-if="row.item.package_limits.volume">
              объем: {{ row.item.package_limits.volume }} м&sup3;
            </li>
            <li v-if="row.item.package_limits.weight">
              вес: {{ row.item.package_limits.weight / 1000 }} кг
            </li>
            <li v-if="row.item.package_limits.dimensional_weight">
              объемный вес: {{ row.item.package_limits.dimensional_weight / 1000 }}
              кг
            </li>
            <li v-if="row.item.package_limits.price">
              оценочная стоимость: {{ row.item.package_limits.price / 100 }} руб
            </li>
          </ul>
          <div
            v-else
            class="text-center"
          >
            нет
          </div>
        </template>
        <template #cell(shipment_limits)="row">
          <ul
            v-if="hasPhysicalLimits(row.item.shipment_limits)"
            class="component-list"
          >
            <li v-if="row.item.shipment_limits.length">
              длина: {{ row.item.shipment_limits.length }} см
            </li>
            <li v-if="row.item.shipment_limits.width">
              ширина: {{ row.item.shipment_limits.width }} см
            </li>
            <li v-if="row.item.shipment_limits.height">
              высота: {{ row.item.shipment_limits.height }} см
            </li>
            <li
              v-if="row.item.shipment_limits.lwh_sum"
              class="text-nowrap"
            >
              сумма трех измерений:
              {{ row.item.shipment_limits.lwh_sum }} см
            </li>
            <li v-if="row.item.shipment_limits.volume">
              объем: {{ row.item.shipment_limits.volume }} м&sup3;
            </li>
            <li v-if="row.item.shipment_limits.weight">
              вес: {{ row.item.shipment_limits.weight / 1000 }} кг
            </li>
            <li v-if="row.item.shipment_limits.dimensional_weight">
              объемный вес: {{ row.item.shipment_limits.dimensional_weight / 1000 }} кг
            </li>
            <li v-if="row.item.shipment_limits.price">
              оценочная стоимость: {{ row.item.shipment_limits.price / 100 }} руб
            </li>
          </ul>
          <div
            v-else
            class="text-center"
          >
            нет
          </div>
        </template>
        <template #cell(publish)="row">
          <b-button
            v-if="row.item.is_publishable"
            variant="info"
            class="ml-2"
            @click="publish(row.item.id)"
          >
            опубликовать
          </b-button>
        </template>
      </b-table>
      <b-button
        variant="info"
        @click="clearCache"
      >
        Сбросить кеш
      </b-button>
    </b-card-text>
  </b-tab>
</template>

<script>
import api from '@/api';
import countryMapper from '@/helpers/country-mapper';

export default {
  name: 'TariffsTab',
  data () {
    return {
      filter: '',
      filterOn: ['tcid'],
      filterOptions: [
        { value: '', text: '' }
      ],
      fields: [
        { key: 'id', label: 'Публичный идентификатор' },
        { key: 'code', label: 'Внутренний идентификатор' },
        { key: 'label', label: 'Название' },
        { key: 'tcid', label: 'ТК' },
        { key: 'delivery_time_options', label: 'Время доставки' },
        { key: 'cod_limits', label: 'Ограничения на НПП' },
        { key: 'package_limits', label: 'Ограничения на коробку' },
        { key: 'shipment_limits', label: 'Ограничения на весь заказ' },
        { key: 'publish', label: 'Публикация' }
      ],
      items: []
    };
  },
  created () {
    this.$store.dispatch('objects/loadTariffs').then(() => this.loadData());
  },
  methods: {
    loadData () {
      const tcids = new Set();
      this.items = this.$store.getters['objects/tariffs'].map(item => {
        tcids.add(item.tcid);
        return item;
      });
      Array.from(tcids).sort().forEach(value => {
        this.filterOptions.push({ value, text: value });
      });
    },
    hasDeliveryOptions (options) {
      return options.length > 0;
    },
    hasCodLimits (limits) {
      return Object.keys(limits).length > 0;
    },
    countryName (country) {
      return countryMapper.countryName(country);
    },
    currencyName (country) {
      return countryMapper.currencyName(country);
    },
    hasPhysicalLimits (limits) {
      return (
        limits.length !== 0 ||
        limits.width !== 0 ||
        limits.height !== 0 ||
        limits.lwh_sum !== 0 ||
        limits.volume !== 0 ||
        limits.weight !== 0 ||
        limits.dimensional_weight !== 0 ||
        limits.price !== 0
      );
    },
    publish (id) {
      alert('publish: ' + id); // todo
    },
    clearCache () {
      api.tariffsClearCache();
    }
  }
};
</script>

<style scoped>
.component-list {
  font-size: 0.8rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
