export default {
  WILDCARD_ID: '*',
  WILDCARD_TITLE: 'Все',

  PRODUCT_LIMITS_ID: '0',
  PRODUCT_LIMITS_TITLE: 'С ограничениями',

  KIND_LOCALITY: 'cty',
  KIND_PRODUCT: 'pro',
  KIND_VENDOR: 'vdr'
};
