<template>
  <div>
    <b-icon-pencil-square
      class="pointer"
      @click="toggle(true)"
    />
    <app-modal
      v-if="show"
      :show="show"
      :title="title"
      cancel-text="Вернуться к редактированию"
      @close="toggle(false)"
    >
      <slot />
    </app-modal>
  </div>
</template>

<script>
import AppModal from '@/components/AppModal';

export default {
  name: 'EditCell',
  components: {
    AppModal
  },
  props: {
    title: { type: String, default: '' },
    close: { type: Boolean, default: false }
  },
  data () {
    return {
      show: false
    };
  },
  watch: {
    close (value) {
      if (value) {
        this.toggle(false);
      }
    }
  },
  methods: {
    toggle (bool) {
      this.show = bool;
    }
  }
};
</script>
