<template>
  <div>
    <b-checkbox v-model="isActive">
      Активно
    </b-checkbox>
    <div class="mt-3">
      Зона*
    </div>
    <b-select
      v-model="zoneId"
      :options="zoneOptions"
      class="mt-2"
    />
    <hr class="zone-tariff-rules_rule-form__hr">
    <tariffs-section
      :tcid="tcid"
      :coid="coid"
      :list="tariffs"
      @update="tariffsSectionUpdated"
      @collapse="isTariffsEdit = !isTariffsEdit"
    />
    <div
      v-if="!isTariffsEdit"
      class="d-flex justify-content-center mt-3"
    >
      <b-button
        variant="info"
        :disabled="tariffs.length === 0"
        @click="save"
      >
        {{ isCreate ? 'Создать правило' : 'Сохранить изменения' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import TariffsSection from '@/pages/ZoneTariffRules/TariffsSection.vue';
import tariffMapper from '@/helpers/tariff-mapper';
import zoneTariffRule from '@/objects/zone-tariff-rule';

export default {
  name: 'RuleForm',
  components: {
    TariffsSection
  },
  props: {
    tcid: { type: String, required: true },
    coid: { type: String, required: true },
    item: { type: Object, required: true }
  },
  data () {
    return {
      isCreate: typeof this.item.id === 'undefined',
      isActive: this.item.isActive || false,
      zoneId: this.item.zoneId || '',
      tariffs: this.item.tariffs || [],
      isTariffsEdit: false
    };
  },
  computed: {
    ...mapGetters({
      activeZones: 'zonetariffrules/activeZones',
      zoneForId: 'zonetariffrules/zoneForId'
    }),
    zoneOptions () {
      return this.isCreate ? this.activeZones : [this.zoneForId(this.zoneId)];
    }
  },
  methods: {
    tariffsSectionUpdated (data) {
      tariffMapper.appendNames(data);
      this.tariffs = data;
    },
    async save () {
      try {
        await api.putZoneTariffRule(zoneTariffRule.create(this.isCreate ? '' : this.item.id, this.tcid, this.coid, this.zoneId, this.tariffs, this.isActive));
        window.location.reload();
      } catch (e) {
        console.error(e.response);
        if (e.response.status === 400) {
          alert(e.response.data.message);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_rule-form {
  &__hr {
    border-style: dashed;
    border-color: rgba(0, 0, 0, .6);
    width: 100%;
    margin: 12px 0;
  }
}
</style>
