<template>
  <b-tab
    title="НП"
    href="#localities"
  >
    <b-card-text>
      <b-row>
        <b-col>
          <localities-filter-form @queryResult="displayData" />
        </b-col>
        <b-col
          cols="3"
          class="p-2 text-right"
        >
          Показано: {{ itemsCount }} из {{ itemsTotal }}
        </b-col>
      </b-row>
      <b-table
        v-if="items.length"
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(region)="row">
          <div class="component-small-text">
            {{ codeAndLabelCellData(row.item.regionCode, row.item.regionLabel) }}
          </div>
        </template>
        <template #cell(area)="row">
          <div class="component-small-text">
            {{ codeAndLabelCellData(row.item.areaCode, row.item.areaLabel) }}
          </div>
        </template>
        <template #cell(level)="row">
          {{ row.item.level || '?' }}
        </template>
      </b-table>
      <div v-if="itemsCount === 1">
        <b-form-select
          v-model="origin"
          :options="whOptions"
          class="mb-2 mr-sm-2 mb-sm-0 component-select-width"
          @change="warehouseSelected"
        >
          <template #first>
            <option
              :value="null"
              disabled
            >
              выберите склад...
            </option>
          </template>
        </b-form-select>
        <b-table
          v-if="cacheData.length > 0"
          :fields="cacheFields"
          :items="cacheData"
          class="mt-3"
        />
      </div>
    </b-card-text>
  </b-tab>
</template>

<script>
import api from '@/api';
import LocalitiesFilterForm from '@/pages/AdminArea/LocalitiesTab/LocalitiesFilterForm';

export default {
  name: 'LocalitiesTab',
  components: {
    LocalitiesFilterForm
  },
  data () {
    return {
      fields: [
        { key: 'fias', label: 'ФИАС', thClass: 'col-md-3' },
        { key: 'level', label: 'Уровень', thClass: 'col-md-1' },
        { key: 'label', label: 'Название' },
        { key: 'region', label: 'Регион' },
        { key: 'area', label: 'Район' }
      ],
      items: [],
      itemsTotal: 0,
      origin: null,
      whOptions: [],
      cacheFields: [
        { key: 'vol', label: 'Объем' },
        { key: 'costs', label: 'Стоимости' }
      ],
      cacheData: []
    };
  },
  computed: {
    itemsCount () {
      return this.items.length;
    }
  },
  created () {
    this.$store.dispatch('objects/loadWarehouses').then(() => {
      this.whOptions = this.$store.getters['objects/warehouses'].map(item => {
        return {
          value: item.address.cityFias,
          text: item.name
        };
      });
    });
  },
  methods: {
    displayData (data) {
      this.items = this.mapLocalities(data.result);
      this.itemsTotal = data.rowsCount;
      this.origin = null;
      this.cacheData = [];
    },
    mapLocalities (items) {
      return items.map(item => {
        return {
          fias: item.fias,
          label: item.label,
          regionCode: item.region_code,
          regionLabel: item.region_label,
          areaCode: item.area_code,
          areaLabel: item.area_label,
          level: item.level
        };
      });
    },
    codeAndLabelCellData (code, label) {
      return code === '' || label === '' ? '' : `[${code}] ${label}`;
    },
    warehouseSelected () {
      api.fetchCache(this.origin, this.items[0].fias).then(response => {
        const data = response.data;
        if (typeof data !== 'object') {
          this.cacheData = [];
          return;
        }
        this.cacheData = Object.entries(data).map(([key, value]) => {
          return {
            vol: key,
            costs: value
          };
        });
      });
    }
  }
};
</script>

<style scoped>
.component-small-text {
  font-size: 0.8rem;
}
.component-select-width {
  width: 12rem;
}
</style>
