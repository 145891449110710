<template>
  <b-modal
    :visible="visible"
    :title="title"
    hide-footer
    no-close-on-backdrop
    size="lg"
    @change="$emit('change')"
  >
    <b-form @submit="onSubmit">
      <b-container class="p-0">
        <b-row align-v="center">
          <b-col cols="3">
            <b-form-checkbox
              v-model="isActive"
              class="component-text"
            >
              Активно
            </b-form-checkbox>
          </b-col>
          <b-col>
            <name-input
              v-model="$v.name.$model"
              :state="validateState('name')"
            />
          </b-col>
        </b-row>
      </b-container>

      <horizontal-line />

      <div class="mt-3">
        <span class="font-14">Товарные бренды</span>
        <vendor-selector
          v-model="$v.vendors.$model"
          :state="validateState('vendors')"
          class="mt-1"
        />
      </div>
      <div class="mt-3">
        <span class="font-14">Список товаров</span>
        <product-selector
          v-model="$v.products.$model"
          :state="validateState('products')"
          class="mt-1"
        />
      </div>
      <b-form-invalid-feedback
        id="object-error-feedback"
        :state="validateState('object')"
      >
        Выберите хотя бы один объект
      </b-form-invalid-feedback>

      <b-container class="mt-3 p-0">
        <b-row align-v="center">
          <b-col cols="5">
            <b-form-checkbox
              v-model="isThreshold"
              class="component-text"
            >
              Порог стоимости
            </b-form-checkbox>
          </b-col>
          <b-col>
            <span class="font-14">Товары, начиная с... (руб.)*</span>
            <threshold-input
              v-model="$v.threshold.$model"
              class="mt-1"
              :state="validateState('threshold')"
              :disabled="!isThreshold"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-container class="mt-3 p-0">
        <b-row align-v="center">
          <b-col>
            <span class="font-14">Длина, см</span>
            <number-input
              v-model="$v.length.$model"
              class="mt-1"
              :state="validateState('length')"
            />
          </b-col>
          <b-col>
            <span class="font-14">Ширина, см</span>
            <number-input
              v-model="$v.width.$model"
              class="mt-1"
              :state="validateState('width')"
            />
          </b-col>
          <b-col>
            <span class="font-14">Высота, см</span>
            <number-input
              v-model="$v.height.$model"
              class="mt-1"
              :state="validateState('height')"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-container class="mt-3 p-0">
        <b-row align-v="center">
          <b-col>
            <span class="font-14">Вес, кг</span>
            <number-input
              v-model="$v.weight.$model"
              :state="validateState('weight')"
              class="mt-1"
            />
          </b-col>
          <b-col>
            <span class="font-14">Объемный вес, кг</span>
            <number-input
              v-model="$v.dimWeight.$model"
              :state="validateState('dimWeight')"
              class="mt-1"
            />
          </b-col>
        </b-row>
      </b-container>

      <horizontal-line variant="dotted" />

      <div>
        <span class="font-14">Список населенных пунктов*</span>
        <city-selector
          v-model="$v.cities.$model"
          :state="validateState('cities')"
          class="mt-1"
        />
      </div>

      <horizontal-line />

      <div class="d-flex align-items-center">
        <span class="font-14 mr-3">Стоимость доставки, руб.</span>
        <cost-input
          v-model="$v.cost.$model"
          :state="validateState('cost')"
        />
      </div>

      <div class="mt-5 text-center">
        <b-button
          type="submit"
          variant="info"
        >
          Создать правило
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength, requiredIf, integer, minValue } from 'vuelidate/lib/validators';

import tuningRule from '@/objects/tuning-rule';

import CitySelector from '@/pages/TuningRules/RuleModal/CitySelector';
import CostInput from '@/pages/TuningRules/RuleModal/CostInput';
import HorizontalLine from '@/pages/TuningRules/RuleModal/HorizontalLine';
import NameInput from '@/pages/TuningRules/RuleModal/NameInput';
import NumberInput from '@/pages/TuningRules/RuleModal/NumberInput';
import ProductSelector from '@/pages/TuningRules/RuleModal/ProductSelector';
import ThresholdInput from '@/pages/TuningRules/RuleModal/ThresholdInput';
import VendorSelector from '@/pages/TuningRules/RuleModal/VendorSelector';

export default {
  name: 'RuleModal',
  components: {
    CitySelector,
    CostInput,
    HorizontalLine,
    NameInput,
    NumberInput,
    ProductSelector,
    ThresholdInput,
    VendorSelector
  },
  mixins: [validationMixin],
  model: { prop: 'visible', event: 'change' },
  props: {
    visible: { type: Boolean },
    service: { type: String, required: true },
    item: { type: Object, default: () => {} },
    handler: { type: Function, required: true }
  },
  data () {
    return {
      isActive: false,
      name: '',
      vendors: [],
      products: [],
      isThreshold: false,
      threshold: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      dimWeight: '',
      cities: [],
      cost: ''
    };
  },
  computed: {
    title () {
      return this.itemIsNotEmpty() ? `Изменение правила "${this.item.idToDisplay()}"` : 'Новое правило';
    }
  },
  watch: {
    visible () {
      if (!tuningRule.isTuningRule(this.item)) {
        this.resetForm();
        return;
      }

      this.isActive = this.item.isActive;
      this.name = this.item.name;
      this.vendors = this.item.vendors;
      this.products = this.item.products;
      this.isThreshold = this.item.isThreshold;
      this.threshold = this.item.isThreshold ? this.item.threshold : '';
      this.length = this.item.length;
      this.width = this.item.width;
      this.height = this.item.height;
      this.weight = this.item.weight;
      this.dimWeight = this.item.dimWeight;
      this.cities = this.item.cities;
      this.cost = this.item.cost;
    }
  },
  validations: {
    name: { required, maxLength: maxLength(100) },
    vendors: {
      required: requiredIf(function () {
        return this.products.length === 0;
      })
    },
    products: {
      required: requiredIf(function () {
        return this.vendors.length === 0;
      })
    },
    threshold: { required: requiredIf(v => v.isThreshold), integer, minValue: minValue(0) },
    length: { integer, minValue: minValue(0) },
    width: { integer, minValue: minValue(0) },
    height: { integer, minValue: minValue(0) },
    weight: { integer, minValue: minValue(0) },
    dimWeight: { integer, minValue: minValue(0) },
    cities: { required },
    cost: { required, integer }
  },
  methods: {
    validateState (name) {
      if (name === 'object') {
        const vendors = this.$v.vendors;
        const products = this.$v.products;

        if (!vendors.$dirty && !products.$dirty) {
          return null;
        }

        return !(vendors.$invalid && products.$invalid);
      }

      const { $dirty, $error } = this.$v[name];

      return $dirty ? !$error : null;
    },
    resetForm () {
      this.isActive = false;
      this.name = '';
      this.vendors = [];
      this.products = [];
      this.isThreshold = false;
      this.threshold = '';
      this.length = '';
      this.width = '';
      this.height = '';
      this.weight = '';
      this.dimWeight = '';
      this.cities = [];
      this.cost = '';

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit (event) {
      event.preventDefault();
      event.stopPropagation();

      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      let id = '';
      let service = this.service;

      if (this.itemIsNotEmpty()) {
        id = this.item.id;
        service = this.item.service;
      }

      this.handler(tuningRule.newTuningRule(
        id,
        service,
        this.isActive,
        this.name,
        this.vendors,
        this.products,
        this.isThreshold,
        this.threshold,
        this.length,
        this.width,
        this.height,
        this.weight,
        this.dimWeight,
        this.cities,
        this.cost
      ));
    },
    itemIsNotEmpty () {
      return typeof this.item === 'object' && this.item !== null;
    }
  }
};
</script>

<style scoped>
.component-text {
  font-size: 0.9rem;
}
</style>
