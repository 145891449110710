<template>
  <b-tab :title="label">
    <b-container
      class="p-0"
      fluid
    >
      <b-row>
        <b-col
          cols="8"
          class="heading"
        >
          Правила
          <info-popover :service="tcid" />
          для ТК "<b>{{ label }}</b>"
        </b-col>
        <b-col class="text-right heading">
          <b-button
            variant="info"
            @click="showModal"
          >
            Создать правило
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      v-sortable="sortableSettings"
      :fields="fields"
      :items="items"
      class="mt-2"
      outlined
    >
      <template #cell(drag)="row">
        <drag-cell
          :data="row.item.idToDisplay()"
          :handle="sortableSettings.handleClass"
          @drop="onDrop"
        />
      </template>
      <template #cell(cities)="row">
        <cities-cell :data="row.item.cities" />
      </template>
      <template #cell(objects)="row">
        <objects-list
          title="Бренды"
          :data="row.item.vendors"
          plain
        />
        <objects-list
          title="Товары"
          :data="row.item.products"
        />
      </template>
      <template #cell(dimensions)="row">
        <dimensions-cell :data="row.item" />
      </template>
      <template #cell(threshold)="row">
        <cost-cell
          :present="row.item.isThreshold"
          :data="row.item.threshold"
        />
      </template>
      <template #cell(cost)="row">
        <cost-cell :data="row.item.cost" />
      </template>
      <template #cell(actions)="row">
        <actions-cell
          :data="row.item.isActive"
          :edit-handler="onEditHandler(row.item)"
          :remove-handler="onRemoveHandler(row.item.id)"
        />
      </template>
    </b-table>

    <rule-modal
      v-model="isModalVisible"
      :service="tcid"
      :item="item"
      :handler="onSubmitHandler()"
    />
  </b-tab>
</template>

<script>
import Sortable from '@/directives/sortable';
import api from '@/api';
import tuningRule from '@/objects/tuning-rule';
import mover from '@/helpers/array-item-mover';
import InfoPopover from '@/pages/TuningRules/InfoPopover';
import RuleModal from '@/pages/TuningRules/RuleModal';
import DragCell from '@/pages/TuningRules/RulesTab/DragCell';
import CitiesCell from '@/pages/TuningRules/RulesTab/CitiesCell';
import CostCell from '@/pages/TuningRules/RulesTab/CostCell';
import ActionsCell from '@/pages/TuningRules/RulesTab/ActionsCell';
import ObjectsList from '@/pages/TuningRules/RulesTab/ObjectsList';
import DimensionsCell from '@/pages/TuningRules/RulesTab/DimensionsCell';

export default {
  name: 'RulesTab',
  directives: {
    Sortable
  },
  components: {
    ObjectsList,
    ActionsCell,
    CostCell,
    DragCell,
    CitiesCell,
    InfoPopover,
    RuleModal,
    DimensionsCell
  },
  props: {
    tcid: { type: String, required: true },
    label: { type: String, required: true }
  },
  data () {
    return {
      fields: [
        { key: 'drag', label: 'ID', thStyle: { textAlign: 'center', width: '8rem' } },
        { key: 'name', label: 'Название правила' },
        { key: 'cities', label: 'Населенные пункты' },
        { key: 'objects', label: 'Область действия' },
        { key: 'dimensions', label: 'Габариты' },
        { key: 'threshold', label: 'Пороговая цена' },
        { key: 'cost', label: 'Стоимость доставки' },
        { key: 'actions', label: 'Активно', thStyle: { width: '6rem' } }
      ],
      items: [],
      sortableSettings: {
        containerSelector: 'tbody',
        handleClass: 'drag-handle',
        dropCallback: this.onDrop
      },
      isModalVisible: false,
      item: undefined
    };
  },
  created () {
    this.setRules();
  },
  methods: {
    showModal (event, item) {
      this.isModalVisible = true;
      this.item = item;
    },
    hideModal () {
      this.isModalVisible = false;
      this.item = undefined;
    },
    setRules () {
      api.fetchTuningRules(this.tcid).then(response => {
        this.items = response.data.map(item => tuningRule.newTuningRule(
          item.id,
          item.service,
          item.isActive,
          item.name,
          item.vendors,
          item.products,
          item.isThreshold,
          item.threshold,
          item.length,
          item.width,
          item.height,
          item.weight,
          item.dimWeight,
          item.cities,
          item.cost
        ));
      }).catch(error => console.error('load rules error', error));
    },
    onDrop (event) {
      api.reorderTuningRules(
        this.tcid,
        mover.moveItem(
          this.items.map(item => item.id),
          event.oldIndex,
          event.newIndex
        ).reverse()
      ).then(() => {
        this.items = [];

        this.setRules();
      }).catch(error => console.error('reorder rules error', error));
    },
    onSubmitHandler () {
      return (item) => {
        api.putTuningRule(
          item.id,
          item.service,
          item.isActive,
          item.name,
          item.vendors,
          item.products,
          item.isThreshold,
          item.threshold,
          item.length,
          item.width,
          item.height,
          item.weight,
          item.dimWeight,
          item.cities,
          item.cost
        ).then(() => {
          this.hideModal();
          this.setRules();
        }).catch(error => {
          this.error = (error.response && error.response.status === 400)
            ? error.response.data.message
            : 'Ошибка создания правила';
        });
      };
    },
    onEditHandler (item) {
      return () => {
        this.showModal(undefined, item);
      };
    },
    onRemoveHandler (id) {
      return () => {
        this.$bvModal.msgBoxConfirm('Удалить правило?', { cancelTitle: 'Отмена' }).then(result => {
          if (result === true) {
            api.deleteTuningRule(id)
              .then(() => this.setRules())
              .catch(error => console.error('deleting rule error', error));
          }
        });
      };
    }
  }
};
</script>

<style scoped>
.heading {
  font-size: 1.1rem;
}
</style>
