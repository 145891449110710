function create (costs, weights, intervals) {
  return {
    costs,
    weights,
    intervals
  };
}

export default {
  create
};
