<template>
  <hr :class="componentClass">
</template>

<script>
export default {
  name: 'HorizontalLine',
  props: {
    variant: { type: String, default: 'solid' }
  },
  computed: {
    componentClass () {
      return 'component-class component-class-' + this.variant;
    }
  }
};
</script>

<style scoped>
    .component-class {
        margin: 1rem 0;
    }
    .component-class-solid {
        border-top: 3px solid rgba(0, 0, 0, 0.1);
    }
    .component-class-dotted {
        border-top: 3px dotted rgba(0, 0, 0, 0.1);
    }
</style>
