<template>
  <div>
    <span class="font-weight-500">Приоритетные правила</span>
    <b-list-group class="mt-2">
      <b-list-group-item
        v-for="item in list"
        :key="item.name"
      >
        <span>{{ item.name }}</span>
        <b-icon-pencil-square
          class="ml-3 pointer"
          @click="edit(item.name)"
        />
        <b-icon-trash
          class="ml-3 pointer"
          @click="remove(item.name)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button
      v-if="!isEdit"
      variant="info"
      size="sm"
      class="mt-2"
      @click="add"
    >
      Добавить приоритетное правило
    </b-button>
    <b-collapse
      v-model="isEdit"
      class="zone-tariff-rules_special-costs-section__form"
    >
      <form @submit.prevent="save">
        <template v-if="type === 'edit'">
          <div class="font-weight-500">
            Редактирование приоритетного правила &laquo;{{ nameForSearch }}&raquo;
          </div>
        </template>
        <template v-else-if="type === 'add'">
          <div class="font-weight-500">
            Создание нового приоритетного правила
          </div>
        </template>
        <div class="mt-2">
          <span>Название правила</span>
          <b-input
            v-model="name"
            class="mt-2"
            required
            autocomplete="off"
          />
        </div>
        <div class="mt-2">
          <span>Бренды</span>
          <ItemSelector
            v-model="vendors"
            class="mt-2"
            placeholder="Товарные бренды"
            :kind="KIND_VENDOR"
          />
        </div>
        <div class="mt-2">
          <span>Список товаров</span>
          <ItemSelector
            v-model="productCodes"
            class="mt-2"
            placeholder="Список товаров"
            :kind="KIND_PRODUCT"
          />
        </div>
        <div class="zone-tariff-rules_special-costs-section__prices">
          <span class="zone-tariff-rules_special-costs-section__title">Цена товара, &#8381;</span>
          <span>от</span>
          <b-input
            v-model="priceFrom"
            type="number"
            min="0"
            autocomplete="off"
          />
          <span>до</span>
          <b-input
            v-model="priceTo"
            type="number"
            min="0"
            autocomplete="off"
          />
        </div>
        <div class="zone-tariff-rules_special-costs-section__prices">
          <span class="zone-tariff-rules_special-costs-section__title">Цена корзины, &#8381;</span>
          <span>от</span>
          <b-input
            v-model="basketPriceFrom"
            type="number"
            min="0"
            autocomplete="off"
          />
          <span>до</span>
          <b-input
            v-model="basketPriceTo"
            type="number"
            min="0"
            autocomplete="off"
          />
        </div>
        <div class="zone-tariff-rules_special-costs-section__prices">
          <span class="flex-shrink-0">Общий вес доставки, кг</span>
          <b-input
            v-model="basketWeightTo"
            type="number"
            min="0"
            autocomplete="off"
          />
        </div>
        <div class="zone-tariff-rules_special-costs-section__prices">
          <span class="flex-shrink-0">Стоимость доставки, &#8381;</span>
          <b-input
            v-model="cost"
            type="number"
            min="0"
            autocomplete="off"
          />
        </div>
        <div class="zone-tariff-rules_special-costs-section__prices">
          <span class="flex-shrink-0">Стоимость интервалов, &#8381;</span>
          <b-icon
            id="tooltip-target"
            tabindex="-1"
            icon="exclamation-circle-fill"
            variant="info"
            class="mr-2"
          />
          <b-tooltip
            target="tooltip-target"
            placement="top"
          >
            -1, если нужно проигнорировать
          </b-tooltip>
          <b-input
            v-model="timeIntervalCost"
            type="number"
            min="-1"
            autocomplete="off"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center mt-3">
          <b-button
            variant="info"
            class="mr-2"
            size="sm"
            type="submit"
          >
            Сохранить приоритетное правило
          </b-button>
          <b-button
            v-if="isEdit"
            variant="light"
            size="sm"
            @click="cancel"
          >
            Отменить создание правила
          </b-button>
        </div>
      </form>
    </b-collapse>
  </div>
</template>

<script>
import constants from '@/constants';
import ItemSelector from '@/pages/TuningRules/RuleModal/ItemSelector';
import zoneTariffSpecialCosts from '@/objects/zone-tariff-special-costs';

export default {
  name: 'SpecialCostsSection',
  components: {
    ItemSelector
  },
  props: {
    list: { type: Array, default () { return []; } }
  },
  data () {
    return {
      KIND_PRODUCT: '',
      KIND_VENDOR: '',
      type: null,
      nameForSearch: null,
      isEdit: false,
      name: null,
      productCodes: [],
      vendors: [],
      priceFrom: 0,
      priceTo: 0,
      basketPriceFrom: 0,
      basketPriceTo: 0,
      basketWeightTo: 0,
      cost: 0,
      timeIntervalCost: -1
    };
  },
  created () {
    this.KIND_PRODUCT = constants.KIND_PRODUCT;
    this.KIND_VENDOR = constants.KIND_VENDOR;
  },
  methods: {
    add () {
      this.type = 'add';
      this.isEdit = true;
      this.$emit('collapse');
      this.name = null;
      this.update(null);
    },
    edit (name) {
      this.type = 'edit';
      this.nameForSearch = this.name = name;
      this.isEdit = true;
      this.$emit('collapse');
      this.update(name);
    },
    remove (name) {
      const idx = this.list.findIndex(item => item.name === name);
      const copy = [...this.list];
      copy.splice(idx, 1);
      this.$emit('update', copy);
    },
    save () {
      if (Number(this.priceTo) < Number(this.priceFrom) && Number(this.priceTo) !== 0) {
        alert('Цена товара "от" должна быть больше цены "до", либо равна нулю');
        return;
      }
      if (Number(this.basketPriceTo) < Number(this.basketPriceFrom) && Number(this.basketPriceTo) !== 0) {
        alert('Цена корзины "от" должна быть больше цены "до", либо равна нулю');
        return;
      }
      const item = zoneTariffSpecialCosts.create(this.name, this.productCodes, this.vendors, this.priceFrom,
        this.priceTo, this.basketPriceFrom, this.basketPriceTo, this.basketWeightTo, this.cost, this.timeIntervalCost);
      if (this.type === 'add') {
        this.$emit('update', [...this.list, item]);
      } else if (this.type === 'edit' && this.nameForSearch) {
        const idx = this.list.findIndex(item => item.name === this.nameForSearch);
        const copy = [...this.list];
        copy[idx] = item;
        this.$emit('update', copy);
      }
      this.isEdit = false;
      this.$emit('collapse');
    },
    update (name) {
      const entry = this.list.find(item => item.name === name);
      if (entry) {
        this.vendors = entry.vendors.map(item => { return { id: item, title: item }; });
        this.productCodes = entry.productCodes.map(item => { return { id: item, title: item }; });
        this.priceFrom = entry.priceFrom;
        this.priceTo = entry.priceTo;
        this.basketPriceFrom = entry.basketPriceFrom;
        this.basketPriceTo = entry.basketPriceTo;
        this.basketWeightTo = entry.basketWeightTo;
        this.cost = entry.cost;
        this.timeIntervalCost = entry.timeIntervalCost;
      } else {
        this.vendors = [];
        this.productCodes = [];
        this.priceFrom = 0;
        this.priceTo = 0;
        this.basketPriceFrom = 0;
        this.basketPriceTo = 0;
        this.basketWeightTo = 0;
        this.cost = 0;
        this.timeIntervalCost = -1;
      }
    },
    cancel () {
      this.isEdit = false;
      this.$emit('collapse');
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_special-costs-section {
  &__form {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 6px;
    margin-top: 8px;
  }
  &__prices {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 8px;
  }
  &__title {
    flex-basis: 137px;
    flex-shrink: 0;
  }
}
</style>
