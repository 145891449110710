<template>
  <div>
    <b-form-input
      :value="value"
      :state="state"
      placeholder="Название правила*"
      aria-describedby="name-error-feedback"
      class="component-text"
      @input="onInput"
    />
    <b-form-invalid-feedback id="name-error-feedback">
      Название должно быть непустой строкой не более 100 символов
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: 'NameInput',
  props: {
    value: { type: String, required: true },
    state: { type: Boolean }
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
    .component-text {
        font-size: 0.9rem;
    }
</style>
