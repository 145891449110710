<template>
  <item-selector
    :value="value"
    placeholder=""
    :kind="KIND_PRODUCT"
    :searchable="searchable"
    :options-filter="filterCallback()"
    @input="onInput"
  />
</template>

<script>
import constants from '@/constants';
import ItemSelector from '@/pages/TuningRules/RuleModal/ItemSelector';

export default {
  name: 'ProductSelector',
  components: {
    ItemSelector
  },
  props: {
    value: { type: Array, required: true }
  },
  data () {
    return {
      KIND_PRODUCT: '',
      searchable: !this.isWildcardOptionChosen(this.value)
    };
  },
  created () {
    this.KIND_PRODUCT = constants.KIND_PRODUCT;
  },
  methods: {
    onInput (newValue) {
      this.searchable = !this.isWildcardOptionChosen(newValue);
      this.$emit('input', newValue);
    },
    filterCallback () {
      return (currentValue, options) => {
        if (currentValue.length > 0) {
          options = this.filterOutWildcardOption(options);
        }
        return options;
      };
    },
    isWildcardOptionChosen (value) {
      return (
        value.length === 1 &&
          (value[0].id === constants.WILDCARD_ID || value[0].id === constants.PRODUCT_LIMITS_ID)
      );
    },
    filterOutWildcardOption (options) {
      return options.filter(item => item.id !== constants.WILDCARD_ID && item.id !== constants.PRODUCT_LIMITS_ID);
    }
  }
};
</script>
