<template>
  <form @submit.prevent="update">
    <b-form-group
      label="Идентификатор"
      label-cols-sm="4"
      label-for="company-id"
    >
      <b-form-input
        id="company-id"
        v-model="id"
        :disabled="!canEditId"
        placeholder="Идентификатор"
      />
    </b-form-group>
    <b-form-group
      label="Название"
      label-cols-sm="4"
      label-for="input-company"
    >
      <b-form-input
        id="input-company"
        v-model="label"
        placeholder="Название"
      />
    </b-form-group>
    <b-form-group
      label="Юрлицо"
      label-cols-sm="4"
      label-for="input-legal"
    >
      <b-select
        id="input-legal"
        v-model="legalId"
        :options="formattedLegals"
        placeholder="Юрлицо"
      />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
        type="submit"
        variant="info"
      >
        Сохранить
      </b-button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CompaniesForm',
  props: {
    item: { type: Object, required: true },
    canEditId: { type: Boolean, default: false }
  },
  data () {
    return {
      id: this.item.id || '',
      label: this.item.label || '',
      legalId: this.item.legalId || ''
    };
  },
  computed: {
    ...mapGetters({
      legals: 'objects/legals'
    }),
    formattedLegals () {
      return this.legals.map(legal => {
        return { value: legal.id, text: legal.label };
      });
    }
  },
  mounted () {
    if (!this.legals.length) {
      this.loadLegals();
    }
  },
  methods: {
    ...mapActions({
      loadLegals: 'objects/loadLegals'
    }),
    update () {
      this.$emit('update', { id: this.id, label: this.label, legalId: this.legalId });
    }
  }
};
</script>
