<template>
  <b-tab
    title="Склады"
    href="#warehouses"
  >
    <b-card-text>
      <b-table
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(info)="row">
          {{ row.item.info[0] }}<br>
          {{ row.item.info[1] }}<br>
          {{ row.item.info[2] }}<br>
          {{ row.item.info[3] }}<br>
          {{ row.item.info[4] }}
        </template>
        <template #cell(synced)="row">
          {{ synchronizedCellData(row.item.synced) }}
        </template>
      </b-table>
      <b-button
        variant="info"
        @click="clearCache"
      >
        Сбросить кеш
      </b-button>
    </b-card-text>
  </b-tab>
</template>

<script>
import api from '@/api';

export default {
  name: 'WarehousesTab',
  data () {
    return {
      fields: [
        { key: 'code', label: 'Код' },
        { key: 'info', label: 'Информация' },
        { key: 'synced', label: 'Синхронизация' }
      ],
      items: []
    };
  },
  created () {
    this.$store.dispatch('objects/loadWarehouses').then(() => this.loadData());
  },
  methods: {
    loadData () {
      this.items = this.$store.getters['objects/warehouses'].map(item => {
        return {
          code: item.code,
          info: [
            `Название: ${item.name}`,
            `ФИАС: ${item.address.fias}`,
            `Адрес: ${item.address.address}`,
            `${item.contactPerson}: ${item.contactPhone} ${item.address.instructions}`,
            `Рабочее время: ${item.workTime}`
          ],
          synced: item.synced
        };
      });
    },
    synchronizedCellData (data) {
      const items = [];

      Object.keys(data).forEach(item => {
        if (data[item]) {
          items.push(item);
        }
      });

      return items.length === 0 ? '' : items.join(', ');
    },
    clearCache () {
      api.warehousesClearCache();
    }
  }
};
</script>
