const tariffMap = {
  tp: {
    std: 'Стандартная доставка',
    dvd: 'Доставка День-в-день',
    vip: 'VIP доставка',
    city: 'Доставка в Сити',
    min90: 'Доставка за 90 минут',
    teslavic: 'Стандартная доставка CyberTruck для `VIC/Семья/Искра`',
    tesla: 'Стандартная доставка CyberTruck',
    xprs: 'Экспресс доставка BORK за 3 часа'
  },
  technopark: {
    technoparkCityCour: 'Доставка в Сити',
    technoparkDvdCour: 'Доставка День-в-день',
    technoparkStdCour: 'Стандартная доставка',
    technoparkStdPp: 'Самовывоз из ТТ'
  },
  holding: {
    holdingDvdCour: 'Доставка День-в-день',
    holdingSmsElectronic: 'Доставка электронных товаров',
    holdingStdCour: 'Стандартная доставка'
  },
  bork: {
    bork90minCour: 'Доставка за 90 минут',
    borkDvdCour: 'Доставка День-в-день',
    borkStdCour: 'Стандартная доставка Bork'
  },
  dpd: {
    dpdPclCour: 'DPD Optimum'
  }
};

const sameDayDeliveryTariffs = ['dvd', 'min90'];

const getTariffNameByCode = function (code) {
  for (const tariffs of Object.values(tariffMap)) {
    if (Object.hasOwn(tariffs, code)) {
      return tariffs[code];
    }
  }
  return 'Неизвестный тариф';
};

export default {
  getTariffNameByCode,
  getTcTariffsCount (tcid) {
    if (!Object.hasOwn(tariffMap, tcid)) {
      return 0;
    }
    return Object.keys(tariffMap[tcid]).length;
  },
  getTariffOptionsByTcId (tcid) {
    if (!Object.hasOwn(tariffMap, tcid)) {
      return [];
    }
    const result = [];
    for (const [code, name] of Object.entries(tariffMap[tcid])) {
      result.push({ value: code, text: name });
    }
    return result;
  },
  appendNames (tariffs) {
    tariffs.forEach(item => { item.name = getTariffNameByCode(item.code); });
  },
  isSameDayDeliveryTariff (code) {
    return sameDayDeliveryTariffs.includes(code);
  }
};
