function create (name, productCodes, vendors, priceFrom, priceTo, basketPriceFrom, basketPriceTo, basketWeightTo, cost, timeIntervalCost) {
  return {
    name,
    productCodes: productCodes.map(item => item.id),
    vendors: vendors.map(item => item.id),
    priceFrom: Number(priceFrom),
    priceTo: Number(priceTo),
    basketPriceFrom: Number(basketPriceFrom),
    basketPriceTo: Number(basketPriceTo),
    basketWeightTo: Number(basketWeightTo),
    cost: Number(cost),
    timeIntervalCost: Number(timeIntervalCost)
  };
}

export default {
  create
};
