import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import HomeScreen from '@/pages/HomeScreen';
import LoginScreen from '@/pages/LoginScreen';
import AdminArea from '@/pages/AdminArea';
import TuningRules from '@/pages/TuningRules';
import PageNotFound from '@/pages/PageNotFound';
import TpArea from '@/pages/TpArea';
import TechnoparkArea from '@/pages/TechnoparkArea';
import BorkArea from '@/pages/BorkArea';
import HoldingArea from '@/pages/HoldingArea';
import DpdKzArea from '@/pages/DpdKzArea';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'home', path: '/', component: HomeScreen },
    { name: 'login', path: '/login', meta: { isOpen: true }, component: LoginScreen },
    { name: 'admin', path: '/admin', components: { default: HomeScreen, content: AdminArea } },
    { name: 'tuning', path: '/tuning', components: { default: HomeScreen, content: TuningRules } },
    { name: 'tp', path: '/tp', components: { default: HomeScreen, content: TpArea } },
    { name: 'technopark', path: '/technopark', components: { default: HomeScreen, content: TechnoparkArea } },
    { name: 'bork', path: '/bork', components: { default: HomeScreen, content: BorkArea } },
    { name: 'holding', path: '/holding', components: { default: HomeScreen, content: HoldingArea } },
    { name: 'dpd-kz', path: '/dpd-kz', components: { default: HomeScreen, content: DpdKzArea } },
    { name: '404', path: '*', components: { default: HomeScreen, content: PageNotFound } }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isOpen)) {
    next();
  } else {
    store.dispatch('auth/isAuthorized').then(() => {
      if (store.getters['auth/isAuthorized']) {
        next();

        return;
      }

      next({ name: 'login' });
    });
  }
});

export default router;
