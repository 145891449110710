function create (code, days, hours, timeLimit, shipmentDays, customWarehouse, intervals, cost, specialCosts, daysOfWeekEnabled, isActive) {
  return {
    code,
    days: Number(days),
    hours: Number(hours),
    timeLimit: Number(timeLimit) || 0,
    shipmentDays: Number(shipmentDays) || 0,
    customWarehouse,
    intervals,
    cost,
    specialCosts,
    daysOfWeekEnabled: daysOfWeekEnabled.sort(),
    isActive
  };
}

export default {
  create
};
