<template>
  <form
    :class="formClass"
    @submit="onSubmit"
  >
    <delivery-interval-selector
      v-if="!isItem"
      v-model="form.id"
      :coid="coid"
      :skip="skip"
    />
    <div :class="fieldClass">
      <b-input
        v-model="form.cost"
        autocomplete="off"
        placeholder="Стоимость в рублях"
        required
        type="number"
        min="0"
        step="1"
      />
    </div>
    <div>
      <b-button
        :class="buttonClass"
        type="submit"
        variant="info"
      >
        {{ isItem ? 'Изменить' : 'Добавить' }}
      </b-button>
    </div>
  </form>
</template>

<script>
import zoneTariffTimeInterval from '@/objects/zone-tariff-time-interval';
import DeliveryIntervalSelector from '@/components/DeliveryIntervalSelector.vue';

const layouts = {
  inline: 'inline',
  column: 'column'
};
Object.freeze(layouts);

export default {
  name: 'TimeIntervalForm',
  components: {
    DeliveryIntervalSelector
  },
  props: {
    layout: { type: String, default: layouts.inline, validator: v => layouts[v] !== undefined },
    coid: { type: String, required: true },
    skip: { type: Array, required: true },
    item: { type: Object, default: () => {} }
  },
  data () {
    const isItem = typeof this.item !== 'undefined';
    return {
      isItem,
      form: {
        id: isItem ? this.item.id : null,
        cost: isItem ? this.item.cost / 100 : null
      }
    };
  },
  computed: {
    formClass () {
      switch (this.layout) {
        case layouts.inline:
          return 'zone-tariff-rules_time-interval-form';
        case layouts.column:
          return 'd-flex flex-column';
        default:
          return '';
      }
    },
    fieldClass () {
      switch (this.layout) {
        case layouts.inline:
          return 'zone-tariff-rules_time-interval-form__field';
        case layouts.column:
          return 'd-flex align-items-center mt-2';
        default:
          return '';
      }
    },
    buttonClass () {
      switch (this.layout) {
        case layouts.inline:
          return '';
        case layouts.column:
          return 'mt-2';
        default:
          return '';
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault();
      if (this.form.id === null) {
        alert('Выберите временной интервал');
        return;
      }
      const data = this.$store.getters['objects/deliveryIntervals'][this.coid].find((item) => item.id === this.form.id);
      const interval = zoneTariffTimeInterval.create(this.form.id, data.from, data.to, this.form.cost * 100);
      this.resetForm();
      this.$emit('submit', interval);
    },
    resetForm () {
      this.form.id = null;
      this.form.cost = null;
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_time-interval-form {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 8px;
  &__field {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 6px;
  }
}
</style>
