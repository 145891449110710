<template>
  <b-form-select
    v-model="selected"
    class="mb-2 mr-sm-2 mb-sm-0"
    :options="options"
    @change="onChange"
  >
    <template #first>
      <option
        :value="null"
        disabled
      >
        выберите склад...
      </option>
    </template>
  </b-form-select>
</template>

<script>
export default {
  name: 'WarehouseSelector',
  props: {
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null }
  },
  data () {
    return {
      selected: null,
      options: []
    };
  },
  created () {
    this.$store.dispatch('objects/loadWarehouses').then(() => {
      this.options = this.$store.getters['objects/warehouses'].map(item => {
        return {
          value: item.code,
          text: item.name
        };
      });
    });
  },
  methods: {
    onChange (value) {
      this.$emit('input', value);
    }
  }
};
</script>
