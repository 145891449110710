<template>
  <b-tab
    title="Юр. лица"
    href="#legals"
  >
    <b-card-text>
      <b-table
        :fields="fields"
        :items="legals"
        outlined
      >
        <template #cell(edit)="data">
          <edit-cell
            :title="`Редактирование Юр. Лица ${data.item.id}`"
            :close="closeEdit"
          >
            <legals-form
              :item="data.item"
              @update="update"
            />
          </edit-cell>
        </template>
      </b-table>
      <b-button
        variant="info"
        @click="show = true"
      >
        Добавить Юр. Лицо
      </b-button>
      <app-modal
        :show="show"
        title="Добавление Юр. лица"
        cancel-text="Вернуться к редактированию"
        @close="show = false"
      >
        <legals-form
          :can-edit-id="true"
          :item="{ id, label }"
          @update="createLegal"
        />
      </app-modal>
    </b-card-text>
  </b-tab>
</template>

<script>
import EditCell from '@/pages/AdminArea/EditCell';
import LegalsForm from '@/pages/AdminArea/LegalsTab/LegalsForm';
import AppModal from '@/components/AppModal';
import { mapGetters } from 'vuex';

export default {
  name: 'LegalsTab',
  components: {
    AppModal,
    EditCell,
    LegalsForm
  },
  data () {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'label', label: 'Название' },
        { key: 'edit', label: '' }
      ],
      id: '',
      label: '',
      show: false,
      closeEdit: false
    };
  },
  computed: {
    ...mapGetters({
      legals: 'objects/legals'
    })
  },
  created () {
    this.$store.dispatch('objects/loadLegals').then(() => this.loadData());
  },
  methods: {
    loadData () {
      this.items = this.$store.getters['objects/legals'].map(item => {
        return { id: item.id, label: item.label };
      });
    },
    createLegal (data) {
      this.show = false;
    },
    update (data) {
      this.closeEdit = true;
      this.$nextTick(() => { this.closeEdit = false; });
    }
  }
};
</script>
