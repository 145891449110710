<template>
  <ul class="component-list">
    <li
      v-for="item in data"
      :key="item.v"
    >
      [<b>{{ item.time_period }}</b>]
      {{ item.description }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DeliveryTimeOptionsCell',
  props: {
    data: { type: Array, required: true }
  }
};
</script>

<style scoped>
.component-list {
  font-size: 0.8rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
