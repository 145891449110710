const insertElement = (arr, index, element) => {
  arr.splice(index, 0, element);
  return arr;
};

const deleteElement = (arr, index) => {
  arr.splice(index, 1);
  return arr;
};

export default {
  moveItem: (arr, oldIndex, newIndex) => {
    if (oldIndex === newIndex) {
      return arr;
    }
    if (oldIndex < newIndex) {
      arr = insertElement(arr, newIndex + 1, arr[oldIndex]);
      arr = deleteElement(arr, oldIndex);
      return arr;
    }
    arr = insertElement(arr, newIndex, arr[oldIndex]);
    arr = deleteElement(arr, oldIndex + 1);
    return arr;
  }
};
