const TCID_DPD = 'dpd';

const TCID_TP = 'tp';

const TCID_BORK = 'bork';
const TCID_HOLDING = 'holding';
const TCID_TECHNOPARK = 'technopark';

export default {
  TCID_DPD,

  TCID_TP,

  TCID_BORK,
  TCID_HOLDING,
  TCID_TECHNOPARK
};
