<template>
  <div>
    <b-navbar
      type="dark"
      variant="info"
    >
      <b-navbar-brand :to="{ name: 'home' }">
        NavBar
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item
          :to="{ name: 'admin' }"
          exact
          exact-active-class="active"
        >
          Админка
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'tuning' }"
          exact
          exact-active-class="active"
        >
          Приор. правила
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'tp' }"
          exact
          exact-active-class="active"
        >
          Собственная доставка v2
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'technopark' }"
          exact
          exact-active-class="active"
        >
          ТК Технопарк
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'bork' }"
          exact
          exact-active-class="active"
        >
          ТК BORK
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'holding' }"
          exact
          exact-active-class="active"
        >
          ТК Холдинг
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'dpd-kz' }"
          exact
          exact-active-class="active"
        >
          DPD Казахстан
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>{{ login }}</em>
          </template>
          <b-dropdown-item @click="logout">
            Выйти
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  computed: {
    login () {
      return this.$store.getters['auth/login'];
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    }
  }
};
</script>
