<template>
  <b-icon-arrow-down-up
    class="component-class"
    :class="handle"
  />
</template>

<script>
import { BIconArrowDownUp } from 'bootstrap-vue';

export default {
  name: 'DragIcon',
  components: {
    BIconArrowDownUp
  },
  props: {
    handle: { type: String, required: true }
  }
};
</script>

<style scoped>
.component-class {
  cursor: move;
}
</style>
