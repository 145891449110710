function create (costs, weights) {
  return {
    costs,
    weights: costs.length > 1 ? weights.slice(1) : []
  };
}

export default {
  create
};
