import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/auth/store';
import objects from '@/store/objects';
import tuningrules from '@/store/tuningrules';
import zonetariffrules from '@/store/zonetariffrules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    objects,
    tuningrules,
    zonetariffrules
  }
});
