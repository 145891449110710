<template>
  <b-card no-body>
    <b-tabs card>
      <rules-tab
        v-for="item in transportCompanies"
        :key="item.tcid"
        :tcid="item.tcid"
        :label="item.label"
      />
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RulesTab from '@/pages/TuningRules/RulesTab';

export default {
  name: 'TuningRules',
  components: {
    RulesTab
  },
  computed: {
    ...mapGetters({
      transportCompanies: 'tuningrules/transportCompanies'
    })
  },
  async created () {
    await this.fetchTransportCompanies();
  },
  methods: {
    ...mapActions({
      fetchTransportCompanies: 'tuningrules/fetchTransportCompanies'
    })
  }
};
</script>
