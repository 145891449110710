<template>
  <b-icon-info class="component-class" />
</template>

<script>
import { BIconInfo } from 'bootstrap-vue';

export default {
  name: 'InfoIcon',
  components: {
    BIconInfo
  }
};
</script>

<style scoped>
.component-class {
  cursor: pointer;
}
</style>
