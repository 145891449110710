<template>
  <div>
    <drag-icon :handle="handle" />
    <span class="component-text ml-2">{{ data }}</span>
  </div>
</template>

<script>
import DragIcon from '@/components/icons/DragIcon';

export default {
  name: 'DragCell',
  components: {
    DragIcon
  },
  props: {
    data: { type: String, required: true },
    handle: { type: String, required: true }
  }
};
</script>

<style scoped>
    .component-text {
        font-size: 0.7rem;
    }
</style>
