<template>
  <div id="app">
    <router-view />
    <router-view name="content" />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>
