<template>
  <form
    class="zone-tariff-rules_weights-form"
    @submit.prevent="add"
  >
    <hr>
    <div class="font-weight-500">
      Весовые интервалы
    </div>
    <div class="zone-tariff-rules_weights-form__fields">
      <div class="zone-tariff-rules_weights-form__field">
        <div class="font-14">
          От, кг *
        </div>
        <b-input
          v-model="weightFrom"
          autocomplete="off"
          :disabled="disabledInputs"
          type="number"
          min="0"
          required
        />
      </div>
      <div class="zone-tariff-rules_weights-form__field">
        <div class="zone-tariff-rules_weights-form__line" />
        <div class="h-100 d-flex align-items-center">
          &mdash;
        </div>
      </div>
      <div class="zone-tariff-rules_weights-form__field">
        <div class="font-14">
          <span class="mr-2">До, кг</span>
          <b-icon
            id="weightInfo"
            tabindex="-1"
            icon="exclamation-circle-fill"
            variant="info"
          />
          <b-tooltip target="weightInfo">
            Для проставления бесконечно большого веса необходимо заполнить только поля "от" и "стоимость", а данное поле оставить пустым.
          </b-tooltip>
        </div>
        <b-input
          v-model="weightTo"
          :disabled="disabledInputs"
          type="number"
          :min="Number(weightFrom) + 1"
          autocomplete="off"
        />
      </div>
      <div class="zone-tariff-rules_weights-form__field">
        <div class="font-14">
          Стоимость, &#8381; *
        </div>
        <b-input
          v-model="cost"
          autocomplete="off"
          :disabled="disabledInputs"
          type="number"
          required
        />
      </div>
      <div class="zone-tariff-rules_weights-form__field">
        <div class="zone-tariff-rules_weights-form__line" />
        <b-button
          variant="info"
          type="submit"
          :disabled="disabledInputs"
        >
          Добавить
        </b-button>
      </div>
    </div>
    <div class="zone-tariff-rules_weights-form__intervals">
      <div
        v-for="(interval, index) in intervals"
        :key="`span${index}`"
        class="d-flex align-items-center"
      >
        <div class="zone-tariff-rules_weights-form__weight">
          От {{ interval.weightFrom }}
          {{ !interval.weightTo ? 'и более' : `до ${interval.weightTo}` }} кг
        </div>
        <div class="mr-2">
          {{ interval.cost }} руб.
        </div>
        <b-icon-pencil-square
          :id="`span${index}`"
          class="pointer mr-2"
        />
        <b-icon-trash
          v-if="index === intervals.length - 1"
          class="pointer"
          @click="remove"
        />
        <b-popover
          :target="`span${index}`"
          triggers="click blur"
        >
          <div class="d-flex align-items-center">
            <span class="mr-2">
              Стоимость, &#8381;
            </span>
            <b-input
              v-model="editCost"
              type="number"
              autocomplete="off"
            />
          </div>
          <b-button
            size="small"
            class="mt-2"
            type="info"
            @click="edit(index)"
          >
            Сохранить
          </b-button>
        </b-popover>
      </div>
    </div>
  </form>
</template>

<script>
import zoneTariffWeightData from '@/objects/zone-tariff-weight-data';
import zoneTariffWeightInterval from '@/objects/zone-tariff-weight-interval';

export default {
  name: 'WeightsForm',
  props: {
    costs: { type: Array, default () { return []; } },
    weights: { type: Array, default () { return []; } },
    intervals: { type: Array, default () { return []; } }
  },
  data () {
    return {
      weightFrom: 0,
      weightTo: null,
      cost: null,
      editCost: null
    };
  },
  computed: {
    disabledInputs () {
      return this.intervals.length ? !this.intervals[this.intervals.length - 1].weightTo : false;
    }
  },
  methods: {
    add () {
      const interval = zoneTariffWeightInterval.create(this.weightFrom, this.weightTo, this.cost);
      const costs = [...this.costs, Number(this.cost)];
      const weights = [...this.weights, this.weightFrom * 1000, this.weightTo * 1000];

      this.weightFrom = this.weightTo;
      this.weightTo = null;
      this.cost = null;

      const data = zoneTariffWeightData.create([...new Set(costs)], [...new Set(weights)], [...this.intervals, interval]);
      if (!this.validate(data)) {
        return;
      }
      this.$emit('update', data);
    },
    edit (idx) {
      const intervals = [...this.intervals];
      intervals[idx].cost = Number(this.editCost);
      const costs = [...this.costs];
      costs[idx] = Number(this.editCost);

      this.$emit('update', zoneTariffWeightData.create(costs, this.weights, intervals));
      this.$root.$emit('bv::hide::popover', `span${idx}`);
    },
    remove () {
      this.$emit('update', zoneTariffWeightData.create(this.costs.slice(0, -1), this.weights.slice(0, -1), this.intervals.slice(0, -1)));
    },
    validate (data) {
      if (
        data.intervals.find(item => Number(item.weightFrom) === Number(this.weightFrom) && Number(item.weightTo) === Number(this.weightTo)) &&
        data.intervals.length > 1
      ) {
        alert('Уже есть интервал с такими весами. Если хотите изменить его стоимость, нажмите на иконку карандаша напротив нужного интервала');
        this.cost = null;
        this.weightFrom = null;
        this.weightTo = null;
        return false;
      }
      if (data.weights[0] !== 0) {
        alert('Первый интервал должен начинаться от нуля килограмм');
        return false;
      }
      const len = data.intervals.length;
      if (len > 1 && data.intervals[len - 1].weightFrom !== data.intervals[len - 2].weightTo) {
        alert('Значение весового интервала должно начинаться со значения, которое было окончанием предыдущего интервала');
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_weights-form {
  &__fields {
    display: flex;
    column-gap: 18px;
    margin-top: 8px;
  }
  &__field {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 6px;
  }
  &__line {
    height: 21px;
  }
  &__intervals {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
  }
  &__weight {
    flex-basis: 242px;
  }
}
</style>
