const mod = (v, m) => {
  return ((v % m) + m) % m;
};
const transform = (v, timezone) => {
  v = Number(v) || 0;
  if (v === 0) { return 0; }
  const result = mod(v + timezone, 24);
  return result === 0 ? 24 : result;
};

export default {
  fromUtc: (v, timezone) => {
    return transform(v, timezone);
  },
  toUtc: (v, timezone) => {
    return transform(v, -timezone);
  }
};
