<template>
  <b-tab
    title="Баз. точки"
    href="#base-points"
  >
    <b-card-text>
      <b-form
        inline
        @submit="e => e.preventDefault()"
      >
        <trans-comp-selector v-model="tcid" />
        <warehouse-selector v-model="whc" />
        <locality-selector
          v-model="cfias"
          class="mr-2"
        />
        <span v-if="hasCalcData()">{{ timestamp.toLocaleString() }} (рассчитано за {{ elapsedTime }} сек)</span>
      </b-form>
    </b-card-text>

    <line-chart
      v-if="isSelected && hasCalcData() && isLoaded && hasGraphData(volGraphData)"
      :chart-data="volGraphData"
      :options="graphOptions('Объем, м3')"
    />

    <b-card-text v-if="isSelected">
      <b-container fluid>
        <b-row>
          <b-col
            v-for="(value, name) in volData"
            :key="name"
            cols="2"
          >
            {{ name }}
            <ul
              v-if="Object.keys(value).length"
              class="component-text component-list"
            >
              <li
                v-for="(item, index) in value"
                :key="index"
              >
                {{ item.tariff }}: {{ item.cost / 100 }} ({{ item.days }})
              </li>
            </ul>
            <div
              v-else
              class="component-text"
            >
              No data
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-button
        class="component-btn"
        variant="info"
        @click="pullBasePoints"
      >
        Посчитать базовые точки
      </b-button>
    </b-card-text>
  </b-tab>
</template>

<script>
import api from '@/api';
import LineChart from '@/pages/AdminArea/BasePointsTab/LineChart';
import LocalitySelector from '@/components/LocalitySelector';
import TransCompSelector from '@/components/TransCompSelector';
import WarehouseSelector from '@/components/WarehouseSelector';

const COLOUR_MAP = {
  CSM: 'rgb(0,191,255)',
  PCL: 'rgb(255,99,71)',
  STD: 'rgb(50,205,50)',
  AUDD: 'rgb(238,130,238)',
  SBKE: 'rgb(33,160,56)',
  CDEK62COUR: 'rgb(0,128,128)',
  CDEK137COUR: 'rgb(0,100,0)',
  CDEK482COUR: 'rgb(30,144,255)',
  RPOSTCOURIERCOUR: 'rgb(128,0,0)',
  RPOSTPARCELPP: 'rgb(0,20,137)',
  YANDDNDDCOUR: 'rgb(255,204,0)',
  YANDDNDDPP: 'rgb(10,10,10)'
};

export default {
  name: 'BasePointsTab',
  components: {
    LineChart,
    LocalitySelector,
    TransCompSelector,
    WarehouseSelector
  },
  data () {
    return {
      tcid: null,
      whc: null,
      cfias: null,
      timestamp: null,
      elapsedTime: 0,
      volData: {},
      volGraphData: {}
    };
  },
  computed: {
    isSelected () {
      return this.tcid && this.whc && this.cfias;
    },
    isLoaded () {
      return typeof this.volGraphData.datasets !== 'undefined';
    }
  },
  watch: {
    tcid () {
      this.fetchBasePoints();
    },
    whc () {
      this.fetchBasePoints();
    },
    cfias () {
      this.fetchBasePoints();
    }
  },
  methods: {
    hasCalcData () {
      return this.timestamp && this.timestamp.getTime() !== (new Date('0001-01-01T00:00:00Z')).getTime();
    },
    hasGraphData (data) {
      return data.labels.length > 0;
    },
    fetchBasePoints () {
      if (this.isSelected) {
        api.basePoints(this.tcid, this.whc, this.cfias).then(response => {
          const data = response.data;

          this.timestamp = new Date(data.timestamp);
          this.elapsedTime = data.elapsed_time;
          this.volData = data.costs;
          this.volGraphData = this.graphData(data.costs, v => v);
        });
      }
    },
    pullBasePoints () {
      this.$bvModal.msgBoxConfirm('Рассчитать для выбранных параметров?', { cancelTitle: 'Отмена' }).then(result => {
        if (result === true) {
          api.calculateBasePoints(this.tcid, this.whc, [this.cfias]);
        }
      });
    },
    graphOptions (xLabel) {
      return {
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true },
            scaleLabel: { display: true, fontSize: 14, labelString: 'Стоимость, руб' }
          }],
          xAxes: [{
            scaleLabel: { display: true, fontSize: 14, labelString: xLabel }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      };
    },
    graphData (payload, xValuesMapFunc) {
      const labels = [];
      const datasets = {};

      for (const [key, value] of Object.entries(payload)) {
        labels.push(xValuesMapFunc(key));

        value.forEach(item => {
          const label = `${item.tariff} (${item.days}д)`;

          if (typeof datasets[label] === 'undefined') {
            datasets[label] = {
              tariff: item.tariff,
              values: []
            };
          }

          datasets[label].values.push(item.cost / 100);
        });
      }

      return {
        labels,
        datasets: Object.entries(datasets).map(item => {
          return {
            label: item[0],
            data: item[1].values,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: COLOUR_MAP[item[1].tariff],
            lineTension: 0
          };
        })
      };
    }
  }
};
</script>

<style scoped>
.component-text {
  font-size: 0.8rem;
  margin: 0 0 1rem 1rem;
}

.component-list {
  list-style: none;
  padding: 0;
}

.component-btn {
  margin-top: 1rem;
}
</style>
