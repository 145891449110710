<template>
  <b-card-body class="zone-tariff-rules_layout">
    <b-tabs
      :value="selectedTab"
      @activate-tab="activateTab"
    >
      <b-tab
        v-for="(company, index) in companiesTabs"
        :key="company.id"
        :title="company.label"
        :active="index === 0"
      />
    </b-tabs>
    <app-modal
      :show="showForm"
      :title="formTitle"
      :cancel-text="formCancelText"
      @close="close"
    >
      <rule-form
        :tcid="tcid"
        :coid="coid"
        :item="item"
      />
    </app-modal>
    <b-button
      class="zone-tariff-rules_layout__create"
      variant="info"
      @click="add"
    >
      Создать правило
    </b-button>
    <b-table
      v-if="items !== null && items.length"
      :fields="fields"
      :items="items"
      class="zone-tariff-rules_layout__table"
      :tbody-tr-class="rowClass"
    >
      <template #cell(name)="data">
        {{ data.item.zone.name }}
      </template>
      <template #cell(priority)="data">
        {{ data.item.zone.priority }}
      </template>
      <template #cell(geo)="data">
        {{ data.item.zone.geoCode }}
      </template>
      <template #cell(isActive)="data">
        {{ data.item.isActive ? 'Активен' : 'Не активен' }}
      </template>
      <template #cell(tariffs)="data">
        <div
          v-for="(tariff, index) in data.item.tariffs"
          :key="index"
        >
          <span :is="tariff.isActive ? 'span' : 'del'">{{ tariff.name }}</span>
        </div>
      </template>
      <template #cell(edit)="data">
        <b-icon-pencil-square
          class="mr-1 pointer"
          @click="edit(data.item)"
        />
        <b-icon-trash
          class="pointer"
          @click="remove(data.item.id)"
        />
      </template>
    </b-table>
    <div
      v-else
      class="d-flex justify-content-center p-3"
    >
      У данной компании нет правил
    </div>
  </b-card-body>
</template>

<script>
import api from '@/api';
import { mapGetters, mapActions } from 'vuex';
import companyMapper from '@/helpers/company-mapper';
import AppModal from '@/components/AppModal';
import RuleForm from '@/pages/ZoneTariffRules/RuleForm.vue';
import { BIconPencilSquare, BIconTrash } from 'bootstrap-vue';

export default {
  name: 'ZoneTariffRules',
  components: {
    AppModal,
    RuleForm,
    BIconPencilSquare,
    BIconTrash
  },
  props: {
    tcid: { type: String, required: true },
    label: { type: String, required: true },
    allowedCompanies: { type: Array, required: true }
  },
  data () {
    return {
      selectedTab: 0,
      showForm: false,
      item: {},
      fields: [
        { key: 'id', label: 'ID правила' },
        { key: 'name', label: 'Название зоны' },
        { key: 'priority', label: 'Приоритет зоны' },
        { key: 'geo', label: 'GEO' },
        { key: 'tariffs', label: 'Тариф/ы' },
        { key: 'edit', label: '' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      companies: 'zonetariffrules/companies',
      items: 'zonetariffrules/rules'
    }),
    companiesTabs () {
      return companyMapper.sorted(this.companies);
    },
    formTitle () {
      return typeof this.item.id === 'undefined' ? `Новое правило для ${this.label}` : `Редактирование правила "${this.item.zone.name}"`;
    },
    formCancelText () {
      return typeof this.item.id === 'undefined' ? 'Вернуться к созданию правила' : 'Вернуться к редактированию';
    },
    coid () {
      return this.companies[this.selectedTab]?.id;
    }
  },
  async created () {
    await this.fetchCompanies(this.allowedCompanies);
    await this.fetchRules({ tcid: this.tcid, coid: this.coid });
  },
  methods: {
    ...mapActions({
      fetchCompanies: 'zonetariffrules/fetchCompanies',
      fetchRules: 'zonetariffrules/fetchRules'
    }),
    activateTab (value) {
      this.selectedTab = value;
      this.fetchRules({ tcid: this.tcid, coid: this.coid });
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return;
      if (!item.isActive) return 'text-hard-muted';
    },
    add () {
      this.item = {};
      this.showForm = true;
    },
    edit (rule) {
      this.item = rule;
      this.showForm = true;
    },
    remove (id) {
      this.$bvModal.msgBoxConfirm('Удалить правило?', { cancelTitle: 'Отмена' }).then(result => {
        if (result === true) {
          api.deleteZoneTariffRule(id)
            .then(() => window.location.reload())
            .catch(error => console.error('deleting rule error', error));
        }
      });
    },
    close () {
      this.showForm = false;
    }
  }
};
</script>

<style lang="scss">
.zone-tariff-rules_layout {
  &__create {
    float: right;
    margin: 12px 0;
  }
  &table td {
    vertical-align: middle;
  }
}
.text-hard-muted {
  color: #dadada !important;
}
</style>
