<template>
  <zone-tariff-rules
    :tcid="tcid"
    label="DPD Казахстан"
    :allowed-companies="['borkkz']"
  />
</template>

<script>
import ZoneTariffRules from '@/pages/ZoneTariffRules/index.vue';
import tcMapper from '@/helpers/tc-mapper';

export default {
  name: 'DpdKzArea',
  components: {
    ZoneTariffRules
  },
  data () {
    return {
      tcid: tcMapper.TCID_DPD
    };
  }
};
</script>
