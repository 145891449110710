<template>
  <b-tab
    title="Компании"
    href="#companies"
  >
    <b-card-text>
      <b-table
        :fields="fields"
        :items="items"
        outlined
      >
        <template #cell(edit)="data">
          <edit-cell
            :title="`Редактирование компании ${data.item.id}`"
            :close="closeEdit"
          >
            <companies-form
              :item="data.item"
              @update="updateCompany"
            />
          </edit-cell>
        </template>
      </b-table>

      <b-button
        variant="info"
        @click="show = true"
      >
        Добавить компанию
      </b-button>
      <app-modal
        :show="show"
        title="Добавление компании"
        cancel-text="Вернуться к редактированию"
        @close="show = false"
      >
        <companies-form
          :can-edit-id="true"
          :item="item"
          @update="putCompany"
        />
      </app-modal>
    </b-card-text>
  </b-tab>
</template>

<script>
import AppModal from '@/components/AppModal';
import EditCell from '@/pages/AdminArea/EditCell';
import CompaniesForm from '@/pages/AdminArea/CompaniesTab/CompaniesForm';

export default {
  name: 'CompaniesTab',
  components: { CompaniesForm, EditCell, AppModal },
  data () {
    return {
      item: {
        id: '',
        label: '',
        legalId: ''
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'label', label: 'Название' },
        { key: 'legalId', label: 'Юрлицо' },
        { key: 'edit', label: '' }
      ],
      items: [],
      show: false,
      closeEdit: false
    };
  },
  created () {
    this.$store.dispatch('objects/loadCompanies').then(() => this.loadData());
  },
  methods: {
    loadData () {
      this.items = this.$store.getters['objects/companies'].map(item => {
        return { id: item.id, label: item.label, legalId: item.legalId };
      });
    },
    putCompany (data) {
      this.item = data;
      this.show = false;
      this.$store.dispatch('objects/putCompany', this.item).then(() => {
        this.item = {
          id: '',
          label: '',
          legalId: ''
        };
        this.loadData();
      });
    },
    updateCompany (data) {
      this.closeEdit = true;
      this.$nextTick(() => { this.closeEdit = false; });
    }
  }
};
</script>

<style scoped>
.flex-new-line {
  flex-basis: 100%;
  height: 0;
}
</style>
