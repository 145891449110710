<template>
  <zone-tariff-rules
    :tcid="tcid"
    label="Собственная доставка v2"
    :allowed-companies="['*']"
  />
</template>

<script>
import ZoneTariffRules from '@/pages/ZoneTariffRules/index.vue';
import tcMapper from '@/helpers/tc-mapper';

export default {
  name: 'TpArea',
  components: {
    ZoneTariffRules
  },
  data () {
    return {
      tcid: tcMapper.TCID_TP
    };
  }
};
</script>
