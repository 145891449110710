<template>
  <b-tab
    title="Разбиения"
    href="#dimensions"
  >
    <calculator-form class="mb-3" />

    <b-table
      :items="items"
      :fields="fields"
      responsive="sm"
      class="component-class"
    >
      <template #cell(calcvolume)="row">
        {{ calculateVolume(row.item.dimensions) }}
      </template>
      <template #cell(calcerror)="row">
        {{ calculateDifference(row.item.volume, calculateVolume(row.item.dimensions)) }}
      </template>
    </b-table>
  </b-tab>
</template>

<script>
import api from '@/api';
import calculator from '@/helpers/lwh-calculator';
import CalculatorForm from '@/pages/AdminArea/DimensionsTab/CalculatorForm';

export default {
  name: 'DimensionsTab',
  components: {
    CalculatorForm
  },
  data () {
    return {
      fields: [
        { key: 'volume', label: 'Объем', sortable: true, 'sort-direction': 'asc' },
        { key: 'dimensions', label: 'ДШВ' },
        { key: 'calcvolume', label: 'Рассчитанный объем' },
        { key: 'calcerror', label: 'Погрешность' }
      ],
      items: []
    };
  },
  created () {
    this.setData();
  },
  methods: {
    setData () {
      api.fetchDimensionsByVolume().then(response => {
        const items = [];

        for (const [key, value] of Object.entries(response.data)) {
          items.push({
            volume: parseFloat(key),
            dimensions: value
          });
        }

        this.items = items;
      }).catch(error => console.error('load dimensions by volume error', error));
    },
    calculateVolume (dimensions) {
      return calculator.volume(dimensions[0], dimensions[1], dimensions[2]);
    },
    calculateDifference (sample, calculated) {
      return calculator.difference(sample, calculated);
    }
  }
};
</script>
