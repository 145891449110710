import auth from '@/auth';
import router from '@/router';

const state = {
  authorized: false,
  invalidCredentials: false,
  user: {},
  refreshing: false
};

const getters = {
  isAuthorized: state => state.authorized,
  isInvalidCredentials: state => state.invalidCredentials,
  login: state => state.user.login,
  isRefreshing: state => state.refreshing
};

const mutations = {
  AUTHORIZED (state, payload) {
    state.authorized = true;
    state.invalidCredentials = false;
    state.user = payload;
  },
  UNAUTHORIZED (state, payload) {
    state.authorized = false;
    state.invalidCredentials = payload.invalidCredentials;
    state.user = {};
    state.refreshing = false;
  },
  REFRESHING (state) {
    state.refreshing = true;
  },
  REFRESHED () {
    state.refreshing = false;
  }
};

const actions = {
  isAuthorized ({ commit, getters }) {
    if (getters.isAuthorized) {
      return;
    }

    return auth.authorized()
      .then(response => commit('AUTHORIZED', response.data))
      .catch(error => {
        if (error.response.status === 401) {
          commit('UNAUTHORIZED', { invalidCredentials: false });

          return;
        }

        console.error('is authorized error', error);
      });
  },
  login ({ commit }, payload) {
    return auth.login(payload.login, payload.password)
      .then(response => commit('AUTHORIZED', response.data))
      .catch(error => {
        if (error.response.status === 401) {
          commit('UNAUTHORIZED', { invalidCredentials: true });

          return;
        }

        console.error('login error', error);
      });
  },
  logout ({ commit }) {
    return auth.logout()
      .then(() => {
        commit('UNAUTHORIZED', { invalidCredentials: false });

        router.push({ name: 'login' });
      })
      .catch(error => console.error('logout error', error));
  },
  refresh ({ commit }) {
    commit('REFRESHING');

    return auth.refresh()
      .then(() => commit('REFRESHED'))
      .catch(error => {
        commit('UNAUTHORIZED', { invalidCredentials: false });

        console.error('logout error', error);

        throw error;
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
