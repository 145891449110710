<template>
  <div>
    <b-form-input
      :value="value"
      :state="aState"
      :disabled="disabled"
      placeholder=""
      aria-describedby="threshold-error-feedback"
      class="component-text"
      @input="onInput"
    />
    <b-form-invalid-feedback id="threshold-error-feedback">
      Порог стоимости должен быть положительным числом
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: 'ThresholdInput',
  props: {
    value: { required: true, validator: prop => typeof prop === 'string' || prop === null },
    state: { type: Boolean },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    aState () {
      return !this.disabled ? this.state : null;
    }
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
.component-text {
  font-size: 0.9rem;
}
</style>
